import React from "react";
import { Route, Routes } from "react-router-dom";
import BarDashboardScreen from "../../screens/bars/BarDashboardScreen";
import BarSignupScreen from "../../screens/bars/BarSignupScreen";
import BarLoginScreen from "../../screens/bars/BarLoginScreen";
import BarAfterSignupScreen from "../../screens/messages/BarAfterSignupScreen";
import BarLoadingScreen from "../../screens/bars/BarLoadingScreen";
import ProtectedRoute from "./ProtectedRoute";
import BarBookingScreen from "../../screens/bars/BarBookingScreen";
import BarAnalyticsScreen from "../../screens/bars/BarAnalyticScreen";
import BarProfileScreen from "../../screens/bars/BarProfileScreen";
import EditBarProfileScreen from "../../screens/bars/EditBarProfileScreen";

const BarRoutes = () => {

  return (
    <Routes>
      <Route path="/" element={<BarDashboardScreen/>} />
      <Route path="/dashboard" element={<ProtectedRoute element={BarDashboardScreen} redirectRoute={"/bar/login"} />} />
      <Route path="/booking" element={<ProtectedRoute element={BarBookingScreen} redirectRoute={"/bar/login"} />} />
      <Route path="/analytics" element={<ProtectedRoute element={BarAnalyticsScreen} redirectRoute={"/bar/login"} />} />
      <Route path="/profile" element={<ProtectedRoute element={BarProfileScreen} redirectRoute={"/bar/login"} />} />
      <Route path="/signup" element={<BarSignupScreen />} />
      <Route path="/login" element={<BarLoginScreen />} />
      <Route path="/after-signup" element={<BarAfterSignupScreen />} />
      <Route path="/loading" element={<BarLoadingScreen />} />
      <Route path="/edit-bar-profile" element={<EditBarProfileScreen />} />
    </Routes>
  );
};

export default BarRoutes;

import React, { useEffect, useState } from "react";
import "./HomeScreen.css"; // Assurez-vous d'ajouter les styles CSS
import { Grid, GridRow, Icon } from "semantic-ui-react";
import logo from "../../assets/logo_no_bg.png"; // Assurez-vous d'ajouter le logo
import logoGoogle from "../../assets/logo/logo-google.png";
import logoAppStore from "../../assets/logo/logo-appstore.png";
// Images pour les features
import profileImage from "../../assets/preview/1.png";
import forumImage from "../../assets/preview/2.png";
import reviewImage from "../../assets/preview/4.png";
import partieImage from "../../assets/preview/3.png";
import chatImage from "../../assets/preview/5.png";
import { sendContactEmail } from "../../services/email"; // Importez la fonction d'envoi de mail

import teamImage from "../../assets/home/team.jpg"; // Remplacez par une image d'équipe ou une illustration

const HomeScreen = () => {
  const [currentFeatureIndex, setCurrentFeatureIndex] = useState(0);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState(""); // Pour afficher un message de succès ou d'erreur

  const scrollToSection = (index) => {
    const sections = document.querySelectorAll(".section");
    if (index >= 0 && index < sections.length) {
      sections[index].scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Empêche le rechargement de la page

    if (!email || !message || !name) {
      setStatus("Tous les champs sont obligatoires.");
      return;
    }

    try {
      sendContactEmail(email, message);
      /*
      const success = await sendContactEmail(email, message);
      if (success) {
        setStatus("Votre message a été envoyé avec succès !");
        setName(""); // Réinitialise le formulaire
        setEmail("");
        setMessage("");
      } else {
        setStatus("Une erreur s'est produite lors de l'envoi.");
      }
        */
    } catch (error) {
      setStatus("Une erreur inattendue s'est produite.");
      console.error(error);
    }
  };

  const features = [
    {
      title: "Profil",
      description:
        "Créez votre espace personnalisé pour partager vos passions et vos préférences en matière de jeux de société. Ajoutez vos jeux préférés, connectez-vous avec d'autres joueurs, et suivez votre activité. Que vous soyez un joueur occasionnel ou un passionné, votre profil devient votre vitrine dans la communauté Ludomit.",
      image: profileImage,
    },
    {
      title: "Forum",
      description:
        "Rejoignez des discussions sur une multitude de sujets : les règles des jeux, des stratégies gagnantes, ou les dernières tendances dans le monde des jeux de société. Publiez des posts, répondez aux questions des autres membres, et enrichissez votre expérience avec des images ou des liens utiles. Le forum est ouvert à tous, que vous soyez curieux ou expert.",
      image: forumImage,
    },
    {
      title: "Reviews",
      description:
        "Accédez à des avis détaillés de la communauté et partagez vos propres critiques pour guider les autres joueurs. Notez les jeux en fonction de leur complexité, du plaisir de jeu, ou de leur originalité. Publiez vos impressions, ajoutez des photos et découvrez de nouvelles pépites grâce aux recommandations personnalisées.",
      image: reviewImage,
    },
    {
      title: "Parties",
      description:
        "Organisez vos parties de jeux où vous le souhaitez : dans un bar à jeux, chez vous, ou dans un lieu public. Filtrez les événements selon vos préférences, invitez vos amis ou découvrez de nouveaux partenaires de jeu. Notre système de notifications vous assure de ne manquer aucune opportunité pour partager des moments uniques.",
      image: partieImage,
    },
    {
      title: "Chat",
      description:
        "Discutez en temps réel avec d'autres membres de la communauté grâce à notre système de messagerie. Envoyez des messages privés, partagez des images, et planifiez vos rencontres en toute simplicité. Que ce soit pour échanger des idées ou coordonner une partie, le chat rend vos interactions fluides et agréables.",
      image: chatImage,
    },
  ];

  useEffect(() => {
    let currentSection = 0;
    const sections = document.querySelectorAll(".section");
    const handleScroll = (event) => {
      if (event.deltaY > 0) {
        // Scrolling down
        if (currentSection < sections.length - 1) {
          currentSection++;
          scrollToSection(currentSection);
        }
      } else {
        // Scrolling up
        if (currentSection > 0) {
          currentSection--;
          scrollToSection(currentSection);
        }
      }
    };
    window.addEventListener("wheel", handleScroll);
    return () => {
      window.removeEventListener("wheel", handleScroll);
    };
  }, []);

  const handleNext = () => {
    setCurrentFeatureIndex((prevIndex) =>
      prevIndex === features.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handlePrevious = () => {
    setCurrentFeatureIndex((prevIndex) =>
      prevIndex === 0 ? features.length - 1 : prevIndex - 1
    );
  };

  return (
    <div className="homepage">
      <header className="header">
        <div className="header-content">
          <a href="/">
          <img src={logo} alt="LudoMit" className="logo" />
          </a>
          <div className="header-buttons">
            <a href="/bar/login" className="header-text-button">
              Connexion
            </a>
            <a href="/bar/signup" className="header-button">
              Inscription
            </a>
          </div>
        </div>
      </header>

      <div className="section-container">
        {/* Landing Section */}
        <section className="section title-section">
          <h1>Bienvenue sur Ludomit</h1>
          <h3>
            Fédérez la communauté des joueurs de jeux de plateau et organisez
            des rencontres inoubliables dans des bars à jeux.
          </h3>
          <br />
          <Grid className="store-button-div" columns="equal" centered>
            <GridRow>
              <a
                href="https://play.google.com/store/apps?hl=fr"
                className="store-button"
              >
                <img src={logoGoogle} alt="Google Play" className="logo" />
                Telecharger l'application sur Android
              </a>
              <a
                href="https://www.apple.com/lu/app-store/"
                className="store-button"
              >
                <img src={logoAppStore} alt="App Store" className="logo" />
                Telecharger l'application sur iOS
              </a>
            </GridRow>
            <GridRow>
              <a href="/bar-services" className="store-button">
                <Icon name="building outline" size="large" />
                Vous êtes un bar à jeux ?
              </a>
            </GridRow>
          </Grid>

          <button className="scroll-arrow" onClick={() => scrollToSection(1)}>
            <Icon name="angle down" size="big" />
          </button>
        </section>

        {/* Features Section */}
        <section className="section value-proposition-section">
          <h2>Les fonctionnalités</h2>

          <div className="feature-slider">
            <button onClick={handlePrevious} className="slider-button">
              <Icon name="angle left" size="big" />
            </button>
            <div className="feature-content">
              <img
                src={features[currentFeatureIndex].image}
                alt={features[currentFeatureIndex].title}
                className="feature-image"
              />
              <h3>{features[currentFeatureIndex].title}</h3>
              <p>{features[currentFeatureIndex].description}</p>
            </div>

            <button onClick={handleNext} className="slider-button">
              <Icon name="angle right" size="big" />
            </button>
          </div>
          <button className="scroll-arrow" onClick={() => scrollToSection(2)}>
            <Icon name="angle down" size="big" />
          </button>
        </section>

        {/* Qui sommes nous ? Section */}
        <section className="section about-section">
          <div className="about-container">
            <h2 className="about-title">Qui sommes-nous ?</h2>
            <div className="about-content">
              <div className="about-text">
                <p>
                  Nous sommes deux jeunes ambitieux, passionnés par les jeux de
                  société et la création de moments inoubliables. Notre amour
                  pour l'innovation et la collaboration nous a permis de
                  rassembler un large panel de compétences pour concevoir une
                  plateforme unique.
                </p>
                <p>
                  Basés en région parisienne, nous partageons une vision commune
                  : fédérer la communauté des amateurs de jeux de société tout
                  en permettant de nouvelles rencontres et de nouvelles
                  expériences.
                </p>
              </div>
              <div className="about-image">
                <img
                  src={teamImage} // Remplacez par une image d'équipe ou une illustration
                  alt="Équipe LudoMit"
                />
              </div>
            </div>
            <div className="about-values">
              <div className="value-item">
                <h3>Ambition</h3>
                <p>
                  Nous repoussons constamment les limites pour atteindre de
                  nouveaux sommets.
                </p>
              </div>
              <div className="value-item">
                <h3>Innovation</h3>
                <p>
                  Nous aimons créer des solutions uniques pour améliorer la vie
                  des joueurs.
                </p>
              </div>
              <div className="value-item">
                <h3>Passion</h3>
                <p>
                  Les jeux de société sont notre moteur pour créer des
                  expériences mémorables.
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* Contact Section */}
        <section className="section contact-section">
          <div className="contact-header">
            <h2>Contactez-nous</h2>
            <p>
              Vous avez des questions ? N'hésitez pas à nous contacter via le
              formulaire ou les moyens ci-dessous.
            </p>
          </div>

          <div className="contact-content">
            {/* Formulaire de contact */}
            <form className="contact-form" onSubmit={handleSubmit}>
        <div className="form-group">
          <input
            type="text"
            placeholder="Nom"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <textarea
            placeholder="Message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
          ></textarea>
        </div>
        <button type="submit" className="submit-button">
          Envoyer
        </button>
        {status && <p className="status-message">{status}</p>}
      </form>

            {/* Informations de contact */}
            <div className="contact-info">
              <h3>Contact direct</h3>
              <p>
                Email :{" "}
                <a href="mailto:contact@ludomit.com">contact@ludomit.com</a>
              </p>
              <p>Suivez-nous :</p>
              <div className="social-icons">
                <a
                  href="https://facebook.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Icon name="facebook" size="big" />
                </a>
                <a
                  href="https://twitter.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Icon name="twitter" size="big" />
                </a>
                <a
                  href="https://instagram.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Icon name="instagram" size="big" />
                </a>
                <a
                  href="https://linkedin.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Icon name="linkedin" size="big" />
                </a>
              </div>
            </div>
          </div>

          <button className="scroll-arrow" onClick={() => scrollToSection(2)}>
            <Icon name="angle up" size="big" />
          </button>
        </section>
      </div>

      <footer className="footer">
        <div className="footer-content">
          <p>© 2023 Ludomit. Tous droits réservés.</p>
          <div className="footer-links">
            <a href="/terms-of-services">CGU</a>
            <a href="/legal-notice">Mentions Légales</a>
            <a href="/privacy-policy">Politique de Confidentialité</a>
          </div>
          <div className="footer-socials">
            <a href="https://facebook.com">
              <Icon name="facebook" />
            </a>
            <a href="https://twitter.com">
              <Icon name="twitter" />
            </a>
            <a href="https://instagram.com">
              <Icon name="instagram" />
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default HomeScreen;

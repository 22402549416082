import React from "react";
import "./EventModal.css";
import { format} from "date-fns";
import { fr } from "date-fns/locale"; // Importation de la locale française
import { Modal, Button, Image } from "semantic-ui-react";
import { rejectEvent, validateEvent } from "../../services/events";

const EventModal = (props) => {
    const getState = (state) => {
        switch (state) {
            case 'pending':
                return 'En attente';
            case 'validated':
                return 'Validé';
            case 'rejected':
                return 'Refusé';
            default:
                return 'En attente';
        }
    }

  return (
        <Modal open={props.open} onClose={props.close}>
          <Modal.Header>Détails de l'événement</Modal.Header>
          <Modal.Content>
            <Image size='small' src={props.selectedBooking.boardgame.image} wrapped />
            <Modal.Description>
              <p><strong>Jeu:</strong> {props.selectedBooking.boardgame.name}</p>
              <p><strong>Durée:</strong> {props.selectedBooking.boardgame.duration}</p>
              <p><strong>Joueurs:</strong> {props.selectedBooking.players.map(player => player.username).join(', ')}</p>
              <p><strong>Date:</strong> {format(new Date(props.selectedBooking.event.date._seconds * 1000), 'dd MMM yyyy HH:mm', { locale: fr })}</p>
              <p><strong>État:</strong> {getState(props.selectedBooking.event.state)}</p>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={props.close}>Fermer</Button>
            <Button positive onClick={async ()=>{
                await validateEvent(props.selectedBooking.event.eventId);
            props.close();
            window.location.reload();
            }}>Valider</Button>
            <Button negative onClick={async () => {
                await rejectEvent(props.selectedBooking.event.eventId);
                props.close();
                window.location.reload();
            }}>Refuser</Button>
          </Modal.Actions>
        </Modal>
      );
};

export default EventModal;

export class Bar {
  constructor(
    id,
    name,
    address,
    city,
    country,
    createdAt,
    isSigned,
    isSubscribed,
    location,
    ludotheque,
    notifications,
    schedule,
    bookings,
    stripeCustomerId,
    profilePicture,
    bannerPicture,
    phoneNumber,
    photos,
    description,
    website,
  ) {
    this.id = id;
    this.name = name;
    this.address = address;
    this.city = city;
    this.country = country;
    this.createdAt = createdAt;
    this.isSigned = isSigned;
    this.isSubscribed = isSubscribed;
    this.location = location;
    this.ludotheque = ludotheque;
    this.notifications = notifications;
    this.schedule = schedule;
    this.bookings = bookings;
    this.stripeCustomerId = stripeCustomerId;
    this.profilePicture = profilePicture;
    this.bannerPicture = bannerPicture;
    this.phoneNumber = phoneNumber;
    this.photos = photos;
    this.description = description;
    this.website = website;

  }

  static empty() {
    return new Bar(
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
    );
  }

  static fromFirestore(docId,data) {
    if (!data) {
      return Bar.empty();
    }

    return new Bar(
      docId || null,
      data.name || null,
      data.address || null,
      data.city || null,
      data.country || null,
      data.createdAt || null,
      data.isSigned !== undefined ? data.isSigned : null,
      data.isSubscribed !== undefined ? data.isSubscribed : null,
      data.location || null,
      data.ludotheque || null,
      data.notifications || null,
      data.schedule || null,
      data.bookings || null,
      data.stripeCustomerId || null,
      data.profilePicture || null,
      data.bannerPicture || null,
      data.phoneNumber || null,
      data.photos || null,
      data.description || null,
      data.website || null,
    );
  }
}

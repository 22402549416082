import { differenceInDays, format, subDays, subMonths ,eachDayOfInterval, eachMonthOfInterval, startOfMonth, endOfMonth } from 'date-fns';

export const calculateEventVariation = (events) => {
  // Créer un tableau pour stocker le nombre d'événements par jour
  const eventCounts = new Array(61).fill(0); // Tableau de 61 jours initialisé à 0

  // Parcourir chaque événement
  events.forEach(event => {
    // Convertir le Timestamp en objet Date
    const eventDate = new Date(event.date.seconds * 1000 + event.date.nanoseconds / 1000000);
    const daysAgo = differenceInDays(new Date(), eventDate);

    // Si l'événement a été créé il y a 60 jours ou moins, incrémenter le compteur correspondant
    if (daysAgo <= 60) {
      eventCounts[60 - daysAgo]++;
    }
  });

  // Calculer le nombre d'événements des 30 derniers jours
  const eventsLast30Days = eventCounts.slice(31, 61).reduce((a, b) => a + b, 0);
  const eventsPrevious30Days = eventCounts.slice(0, 30).reduce((a, b) => a + b, 0);

  // Calculer la variation en pourcentage
  const variation = ((eventsLast30Days - eventsPrevious30Days) / (eventsPrevious30Days || 1)) * 100;

  // Ajouter le signe "+" ou "-" selon la variation
  const variationString = variation >= 0 ? `+${variation.toFixed(1)}%` : `${variation.toFixed(1)}%`;

  // Retourner la variation
  return variationString;
};

export const formatEventsForLineChart = (events, range = '30days') => {
  let dateInterval;
  if (range === '12months') {
    const today = new Date();
    const startDate = subMonths(today, 11);
    dateInterval = eachMonthOfInterval({ start: startOfMonth(startDate), end: endOfMonth(today) });
  } else {
    const days = range === '7days' ? 7 : 30;
    const today = new Date();
    const startDate = subDays(today, days);
    dateInterval = eachDayOfInterval({ start: startDate, end: today });
  }

  const eventCounts = {};
  events.forEach(event => {
    const eventDate = new Date(event.date.seconds * 1000 + event.date.nanoseconds / 1000000);
    const formattedDate = range === '12months' ? format(eventDate, 'yyyy-MM') : format(eventDate, 'yyyy-MM-dd');

    if (!eventCounts[formattedDate]) {
      eventCounts[formattedDate] = 0;
    }
    eventCounts[formattedDate]++;
  });

  const data = dateInterval.map(date => {
    const formattedDate = range === '12months' ? format(date, 'yyyy-MM') : format(date, 'yyyy-MM-dd');
    return {
      name: formattedDate,
      reservations: eventCounts[formattedDate] || 0,
    };
  });

  return data;
};
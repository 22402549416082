import {
  doc,
  getDoc,
  updateDoc,
  arrayRemove,
  arrayUnion,
} from "firebase/firestore";
import { firestore, auth, functions, storage} from "./firebase";
import { Bar } from "../models/bar_model";
import { addShowDetails } from "../utils/barUtils";
import { httpsCallable } from "firebase/functions";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";

export const verifyBarState = async () => {
  try {
    const uid = auth.currentUser.uid;
    const docRef = doc(firestore, "bars", uid);
    const docSnap = await getDoc(docRef);
    console.log(docSnap);
    const isSubscribe = docSnap.data().isSubscribed;
    var result;
    switch (isSubscribe) {
      case true:
        result = "Bar is subscribed";
        break;
      case false:
        result = "Bar is not subscribed";
        break;
      default:
        result = "Bar is not signed";
        break;
    }
    return result;
  } catch (error) {
    console.error("Error fetching document:", error);
  }
};

export const getBarWithId = async (barId) => {
  try {
    const docRef = doc(firestore, "bars", barId);
    const docSnap = await getDoc(docRef);
    const result = Bar.fromFirestore(docSnap.id, docSnap.data());
    return result;
  } catch (error) {
    console.error("Error fetching document:", error);
  }
};

export const clearNotifications = async (barId) => {
  const barDocRef = doc(firestore, "bars", barId);
  try {
    await updateDoc(barDocRef, {
      notifications: [],
    });
    console.log("Notifications cleared successfully");
  } catch (error) {
    console.error("Error clearing notifications: ", error);
  }
};

export const removeNotificationAtIndex = async (barId, index) => {
  const barDocRef = doc(firestore, "bars", barId);
  try {
    const barDocSnapshot = await getDoc(barDocRef);
    if (barDocSnapshot.exists()) {
      const barData = barDocSnapshot.data();
      const notifications = barData.notifications || [];
      if (index >= 0 && index < notifications.length) {
        notifications.splice(index, 1);
        await updateDoc(barDocRef, {
          notifications: notifications,
        });
        console.log("Notification removed successfully");
      } else {
        console.error("Index out of bounds");
      }
    } else {
      console.error("Document does not exist");
    }
  } catch (error) {
    console.error("Error removing notification: ", error);
  }
};

export const updateBarField = async (barId, field, value) => {
  const barDocRef = doc(firestore, "bars", barId);
  try {
    await updateDoc(barDocRef, {
      [field]: value,
    });
  } catch (error) {
    console.error(`Error updating field ${field}: `, error);
  }
};
export const uploadBannerPicture = async (barId, userId, file) => {
  try {
    console.log(file);
    // Vérifier que le fichier est une image
    if (!file.type.startsWith("image/")) {
      console.log(file);
      throw new Error("Le fichier sélectionné n'est pas une image valide.");
    }

    // Extraire l'extension du fichier (ex: .jpg, .png)
    const extension = file.name.split(".").pop();
    const fileName = `${userId}_banner.${extension}`;
    const fileRef = ref(storage, `bars/${fileName}`);

    // Upload du fichier dans Firestore Storage
    await uploadBytes(fileRef, file);

    // Obtenir l'URL de téléchargement
    const downloadURL = await getDownloadURL(fileRef);

    // Mettre à jour Firestore avec l'URL
    await updateBarField(barId, "bannerPicture", downloadURL);

    console.log("Bannière mise à jour avec succès !");
    return downloadURL;
  } catch (error) {
    console.error("Erreur lors de l'upload de la bannière :", error);
    throw error;
  }
};

export const uploadProfilePicture = async (barId, userId, file) => {
  try {
    // Vérifier que le fichier est une image
    if (!file.type.startsWith("image/")) {
      throw new Error("Le fichier sélectionné n'est pas une image valide.");
    }

    // Extraire l'extension du fichier (ex: .jpg, .png)
    const extension = file.name.split(".").pop();
    const fileName = `${userId}_profile.${extension}`;
    const fileRef = ref(storage, `bars/${fileName}`);

    // Upload du fichier dans Firestore Storage
    await uploadBytes(fileRef, file);

    // Obtenir l'URL de téléchargement
    const downloadURL = await getDownloadURL(fileRef);

    // Mettre à jour Firestore avec l'URL
    await updateBarField(barId, "profilePicture", downloadURL);

    console.log("Photo de profil mise à jour avec succès !");
    return downloadURL;
  } catch (error) {
    console.error("Erreur lors de l'upload de la photo de profil :", error);
    throw error;
  }
};

export const uploadPhotos = async (barId, userId, files) => {
  try {
    // Récupérer les photos existantes dans Firestore
    const barDocRef = doc(firestore, "bars", barId);
    const barDoc = await getDoc(barDocRef);
    const existingPhotos = barDoc.exists() && barDoc.data().photos ? barDoc.data().photos : [];

    // Nouvelle liste de photos après ajout
    const newPhotos = [...existingPhotos];

    // Upload de chaque fichier sélectionné
    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      // Vérifier que le fichier est une image
      if (!file.type.startsWith("image/")) {
        console.warn(`Le fichier ${file.name} n'est pas une image valide.`);
        continue; // Ignorer les fichiers non-images
      }

      // Générer le nom du fichier avec un index unique
      const fileName = `${userId}_${existingPhotos.length + i + 1}`;
      const fileRef = ref(storage, `bars/${fileName}`);

      // Upload du fichier dans Firebase Storage
      await uploadBytes(fileRef, file);

      // Obtenir l'URL de téléchargement
      const downloadURL = await getDownloadURL(fileRef);

      // Ajouter l'URL à la liste des photos
      newPhotos.push(downloadURL);
    }

    // Mettre à jour Firestore avec la nouvelle liste de photos
    await updateBarField(barId, "photos", newPhotos);

    console.log("Photos mises à jour avec succès !");
    return newPhotos;
  } catch (error) {
    console.error("Erreur lors de l'upload des photos :", error);
    throw error;
  }
};

export const addBoardGame = async (barId, boardGameId) => {
  const barDocRef = doc(firestore, "bars", barId);
  try {
    await updateDoc(barDocRef, {
      ludotheque: arrayUnion(boardGameId),
    });
  } catch (error) {
    console.error(
      `Error adding board game ${boardGameId} to bar ${barId}: `,
      error
    );
  }
};

export const removeBoardGame = async (barId, boardGameId) => {
  const barDocRef = doc(firestore, "bars", barId);
  try {
    await updateDoc(barDocRef, {
      ludotheque: arrayRemove(boardGameId),
    });
  } catch (error) {
    console.error(
      `Error removing board game ${boardGameId} from bar ${barId}: `,
      error
    );
  }
};

export const getBarSchedule = async (barId) => {
  const barDocRef = doc(firestore, "bars", barId);
  try {
    const docSnap = await getDoc(barDocRef);
    if (docSnap.exists()) {
      const schedule = docSnap.data().schedule || {
        Lundi: {
          enabled: false,
          openAm: "",
          closeAm: "",
          openPm: "",
          closePm: "",
          showDetails: false,
        },
        Mardi: {
          enabled: false,
          openAm: "",
          closeAm: "",
          openPm: "",
          closePm: "",
          showDetails: false,
        },
        Mercredi: {
          enabled: false,
          openAm: "",
          closeAm: "",
          openPm: "",
          closePm: "",
          showDetails: false,
        },
        Jeudi: {
          enabled: false,
          openAm: "",
          closeAm: "",
          openPm: "",
          closePm: "",
          showDetails: false,
        },
        Vendredi: {
          enabled: false,
          openAm: "",
          closeAm: "",
          openPm: "",
          closePm: "",
          showDetails: false,
        },
        Samedi: {
          enabled: false,
          openAm: "",
          closeAm: "",
          openPm: "",
          closePm: "",
          showDetails: false,
        },
        Dimanche: {
          enabled: false,
          openAm: "",
          closeAm: "",
          openPm: "",
          closePm: "",
          showDetails: false,
        },
      };
      const scheduleWithShowDetails = addShowDetails(schedule);
      return scheduleWithShowDetails;
    } else {
      console.log("No such document!");
      return null;
    }
  } catch (error) {
    console.error("Error fetching document:", error);
    return null;
  }
};

export const fetchAnalyticsData = async (barId) => {
  const getAnalytics = httpsCallable(functions, "getAnalytics");

  try {
    const result = await getAnalytics({ barId });
    const { events, mostPopularGames } = result.data;
    return { events, mostPopularGames };
  } catch (error) {
    console.error("Error fetching analytics:", error);
  }
};

export const fetchBookingsData = async (barId) => {
  const getBookings = httpsCallable(functions, "getBookings");

  try {
    const result = await getBookings({ barId });
    // Retourner les données si nécessaire
    return result.data;
  } catch (error) {
    console.error("Error fetching analytics:", error);
  }
};

import { auth } from "./firebase";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendEmailVerification
} from "firebase/auth";
import { signOut } from "firebase/auth";
import { sendAfterSignupEmail } from "./email";
import useBarAuthStore from "../stores/bar_auth_store";
import useMessageStore from "../stores/message_store";
import { getBarWithId } from "./bars";


const { showMessage } = useMessageStore.getState();
const { setBarUser,setEmail, setIsAuthenticated} = useBarAuthStore.getState();

export const signup = async (email, password, confirmPassword) => {

  try {
    if (password !== confirmPassword) {
      console.log("Les mots de passe ne correspondent pas");
      showMessage("Les mots de passe ne correspondent pas", "error");
      return;
    }
    var user;
    await createUserWithEmailAndPassword(auth, email, password).then(
      (userCredential) => {
        user = userCredential.user;
        setBarUser(user);
        setEmail(email);
      }
    );

    // Envoi d'un email de vérification
    await sendEmailVerification(user);

    // Envoi d'un email de bienvenue
    await sendAfterSignupEmail(email).then((value) => {
      console.log("Email de bienvenue envoyé : ", value);
      window.location.href = process.env.REACT_APP_URL_PREFIX + "/bar/after-signup";
      });

    
  } catch (error) {
    console.log("Erreur lors de l'inscription", error);
    showMessage("Une erreur s'est produite", "error");
  }
};


//login
export const login = async (email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {
        const user = userCredential.user;
        const userBar = await getBarWithId(user.uid)
        setEmail(email);
        setBarUser(userBar);
        if(userBar.isSigned === true){
        window.location.href = process.env.REACT_APP_URL_PREFIX + "/bar/dashboard";
        }
        else{
          window.location.href = process.env.REACT_APP_URL_PREFIX + "/subscription";
        }
      })
      .catch((error) => {
        showMessage("Une erreur s'est produite", "error");
        console.log("Erreur lors de la connexion", error);
      });
  } catch (error) {
    
    console.log("Erreur lors de la connexion", error);
  }
};

//logout

export const logout = () => {
  try {
    signOut(auth)
      .then(() => {
        setBarUser(null);
        setEmail(null);
        setIsAuthenticated(null);
        window.location.href =
          process.env.REACT_APP_URL_PREFIX + "/bar/login";
        console.log("Déconnexion réussie");
      })
      .catch((error) => {
        console.log("Erreur lors de la déconnexion", error);
      });
  } catch (error) {
    console.log("Erreur lors de la déconnexion", error);
  }
};

export const getAuthState = () => {
  return auth.currentUser !== null;
};

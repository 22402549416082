import React from "react";

const PrivacyPolicyScreen = () => {

  return (
    <div className="privacy-policy-container">
      <h1 className="title">Politique de Confidentialité</h1>

      <section>
        <p>
          Ludomit, soucieux des droits des utilisateurs et de la transparence, a
          mis en place cette politique de confidentialité. Elle détaille les types
          de données collectées, leur utilisation, leur conservation et vos droits.
        </p>
        <p>
          Pour toute question relative à la protection des données personnelles,
          veuillez consulter :{" "}
          <a href="https://www.cnil.fr" target="_blank" rel="noopener noreferrer">
            www.cnil.fr
          </a>{" "}
          ou contactez-nous à{" "}
          <a href="mailto:contact@Ludomit.com">contact@Ludomit.com</a>.
        </p>
      </section>

      <section>
        <h2>Collecte de données personnelles</h2>
        <p>
          Les données collectées incluent :
        </p>
        <ul>
          <li>Nom, prénom, adresse e-mail, numéro de téléphone</li>
          <li>Données de localisation (si autorisées)</li>
          <li>Données comportementales (interactions dans l'application)</li>
        </ul>
        <p>
          Ces données sont collectées via :
        </p>
        <ul>
          <li>Formulaires remplis dans l'application</li>
          <li>Cookies et outils d'analyse tiers</li>
        </ul>
      </section>

      <section>
        <h2>Utilisation et partage des données</h2>
        <p>
          Les données sont utilisées pour :
        </p>
        <ul>
          <li>Personnaliser les services</li>
          <li>Améliorer l'expérience utilisateur</li>
          <li>Prévenir les fraudes</li>
        </ul>
        <p>
          Les données peuvent être partagées avec des tiers pour :
        </p>
        <ul>
          <li>Hébergement et analyses statistiques</li>
          <li>Respect des obligations légales</li>
          <li>Fusions ou acquisitions</li>
        </ul>
        <p>Nous ne vendons pas vos données personnelles.</p>
      </section>

      <section>
        <h2>Conservation des données</h2>
        <p>
          Vos données sont conservées pendant la relation contractuelle et jusqu'à
          12 mois après sa fin. Certaines données peuvent être conservées plus
          longtemps pour répondre à des obligations légales.
        </p>
      </section>

      <section>
        <h2>Vos droits</h2>
        <p>
          Conformément à la réglementation, vous disposez des droits suivants :
        </p>
        <ul>
          <li>Accès : Obtenir une copie de vos données</li>
          <li>Rectification : Corriger des données incorrectes</li>
          <li>Suppression : Demander la suppression de vos données</li>
          <li>Portabilité : Transférer vos données à un tiers</li>
        </ul>
        <p>
          Pour exercer vos droits, contactez-nous à{" "}
          <a href="mailto:contact@Ludomit.com">contact@Ludomit.com</a>.
        </p>
      </section>

      <section>
        <h2>Consentement</h2>
        <p>
          En utilisant notre application, vous acceptez la collecte et l'utilisation
          de vos données conformément à cette politique. Vous pouvez retirer votre
          consentement en désinstallant l'application ou en nous contactant.
        </p>
      </section>

      <section>
        <h2>Mises à jour de cette politique</h2>
        <p>
          Nous nous réservons le droit de modifier cette politique de confidentialité.
          Toute mise à jour sera publiée sur cette page avec une date de modification.
          Nous vous encourageons à consulter cette page régulièrement.
        </p>
      </section>

      <section>
        <h2>Loi applicable</h2>
        <p>
          Cette politique est régie par la loi française. Pour toute question,
          contactez-nous à{" "}
          <a href="mailto:contact@Ludomit.com">contact@Ludomit.com</a>.
        </p>
      </section>
    </div>
  );
};

export default PrivacyPolicyScreen;
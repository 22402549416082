import { React, useEffect, useState } from "react";
import { verifySession } from "../services/auth";
import {transferBarFromOldIdToNewId, addTestBar, importBarsFromCSV } from "../services/bars";
import { importBoardgames } from "../services/boardgames";

const AdminDashboardScreen = () => {
  const [oldId, setOldId] = useState("");
  const [newId, setNewId] = useState("");
  const [isImporting, setIsImporting] = useState(false);


  useEffect(() => {
    verifySession();
  }, []);

  return (
    <div>
        <h1>Hello admin</h1>
        <hr/>
        <h2>Welcome to your dashboard</h2>
        
        <div style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}>
        <h3>Lier un bar avec un nouvel inscrit</h3>
      <label htmlFor="oldId">Ancien Id</label>
        <input
        className="input"
        type="text"
        id="oldId"
        name="oldId"
        value={oldId}
        onChange={(e) => {
          setOldId(e.target.value);
        }}
        required
      />
      <label htmlFor="oldId">Nouveu Id</label>
      <input
        className="input"
        type="text"
        id="newId"
        name="newId"
        value={newId}
        onChange={(e) => {
          setNewId(e.target.value);
        }}
        required
      />
      <button
        className="small_button_orange_background"
        type="submit"
        onClick={() => transferBarFromOldIdToNewId(oldId, newId)}
      >
        Connexion
      </button>
        </div>
      <hr/>
      <h2>Importer les boardgames</h2>
      {isImporting && <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "50px",
      }}>
        <div style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "lightgrey",
          width: "40%",
          borderRadius: "10px",
          border: "1px solid black", 
      }}>
        <p>Importing...</p>
        </div></div>}
      <button
        className="small_button_orange_background"
        type="submit"
        onClick={async () => {
          setIsImporting(true);
          await importBoardgames();
          setIsImporting(false);
        }}
      >
        Importer les boardgames
      </button>
      <button
        className="small_button_orange_background"
        type="submit"
        onClick={async () => {
          await importBarsFromCSV("");
        }}
      >
        ajouter les bars
      </button>

    </div>
  );
};

export default AdminDashboardScreen;

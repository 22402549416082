import { React, useState } from "react";
import { login } from "../services/auth";

const AdminLoginScreen = () => {
  const [password, setPassword] = useState("");

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        width: "100vw",
      }}
    >
      <label htmlFor="password">Mot de passe</label>
      <input
        className="input"
        type="password"
        id="password"
        name="password"
        value={password}
        onChange={(e) => {
          setPassword(e.target.value);
        }}
        required
      />
      <button
        className="small_button_orange_background"
        type="submit"
        onClick={() => login(password)}
      >
        Connexion
      </button>
    </div>
  );
};

export default AdminLoginScreen;

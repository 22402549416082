import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, useStripe } from "@stripe/react-stripe-js";
import { getFunctions, httpsCallable } from "firebase/functions";
import useBarAuthStore from "../../stores/bar_auth_store";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const CheckoutForm = () => {
  const stripe = useStripe();
  const { barUser } = useBarAuthStore();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    const functions = getFunctions();
    const createCheckoutSession = httpsCallable(functions, "createCheckoutSession");

    try {
      const {
        data: { sessionId },
      } = await createCheckoutSession({
        barId: barUser.id,
        success_url: window.location.origin,
        cancel_url: window.location.origin,
      });

      const { error } = await stripe.redirectToCheckout({ sessionId });

      if (error) {
        setError(error.message);
      }
    } catch (error) {
      console.error("Error creating checkout session", error);
      setError("An error occurred. Please try again.");
    }

    setLoading(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <button className="subscription-button" type="submit" disabled={!stripe || loading}>
        {loading ? "Processing..." : barUser.isSubscribed ? "Gérer son abonnement" : "S'abonnez maintenant"}
      </button>
      {error && <div>{error}</div>}
    </form>
  );
};

const StripeCheckoutButton = () => (
  <Elements stripe={stripePromise}>
    <CheckoutForm />
  </Elements>
);

export default StripeCheckoutButton;

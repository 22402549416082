export const getHash = async (password) => {
    const encoder = new TextEncoder();
  const data = encoder.encode(password);
  const hashBuffer = await crypto.subtle.digest('SHA-256', data);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('');
  return hashHex;
}

export const generateUniqueString = () => {
    const timestamp = Date.now().toString(36); // Convertir l'horodatage en base 36
  const randomString = Math.random().toString(36).substring(2, 15); // Générer une chaîne aléatoire en base 36
  return timestamp + randomString;
}
import { doc, updateDoc } from "firebase/firestore";
import { firestore } from "./firebase";

export const validateEvent = async (eventId) => {
  const barDocRef = doc(firestore, "events", eventId);
  try {
    await updateDoc(barDocRef, {
      state: "validated",
    });
  } catch (error) {
    console.error(`Error updating state : `, error);
  }
};

export const rejectEvent = async (eventId) => {
  const EventDocRef = doc(firestore, "events", eventId);
  try {
    await updateDoc(EventDocRef, {
      state: "rejected",
    });
  } catch (error) {
    console.error(`Error updating state : `, error);
  }
};

export const removeShowDetails = (schedule) => {
  const newSchedule = {};
  for (const day in schedule) {
    const { showDetails, ...rest } = schedule[day];
    newSchedule[day] = rest;
  }
  return newSchedule;
};

export const addShowDetails = (schedule) => {
  const newSchedule = {};
  for (const day in schedule) {
    newSchedule[day] = {
      ...schedule[day],
      showDetails: schedule[day].enabled,
    };
  }
  return newSchedule;
};
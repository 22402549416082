import React, { useState, useEffect } from "react";
import { Modal, Input, Button } from "semantic-ui-react";
import { collection, getDocs, getDoc } from "firebase/firestore";
import { addBoardGame } from "../../services/bars";
import "./BoardGameModal.css";
import BoardGameCard from "../cards/BoarGameCard";
import { firestore } from "../../services/firebase";
import useBarAuthUser from "../../stores/bar_auth_store";
import useMessageStore from "../../stores/message_store";
import BoardGame from "../../models/boardgame_model";

const BoardGameModal = ({ isOpen, onClose, barId}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [boardGames, setBoardGames] = useState([]);
  const [filteredBoardGames, setFilteredBoardGames] = useState([]);
  const [selectedGames, setSelectedGames] = useState([]);
  const { barUser, updateBarUser } = useBarAuthUser();
  const { showMessage } = useMessageStore();

  useEffect(() => {
    const fetchBoardGames = async () => {
      try {
        const boardGamesCollection = collection(firestore, "boardgames");
        const boardGamesSnapshot = await getDocs(boardGamesCollection);
        const boardGamesList = await Promise.all(
          boardGamesSnapshot.docs.map(async (doc) => {
            const boardGameData = doc.data();

            // Fetch reviews for the board game
            const reviews = boardGameData.reviews || [];

            try {
              const fetchedReviews = await Promise.all(
                reviews.map(async (reviewId) => {
                  const reviewDocRef = doc(firestore, "userReviews", reviewId);
                  const reviewDoc = await getDoc(reviewDocRef);
                  return reviewDoc.exists()
                    ? { id: reviewDoc.id, ...reviewDoc.data() }
                    : null;
                })
              );
              boardGameData.reviews = fetchedReviews.filter(
                (review) => review !== null
              );
            } catch (error) {
              console.error("Error fetching reviews:", error);
              boardGameData.reviews = [];
            }
            
            
            return BoardGame.fromFirestore(boardGameData, doc.id);
          })
        );
        //remove games already in the bar
        const barGames = barUser.ludotheque;
        const filteredGames = boardGamesList.filter((game) => !barGames.includes(game.id));
        setBoardGames(boardGamesList);
        setFilteredBoardGames(filteredGames);
      } catch (error) {
        console.error("Error fetching board games:", error);
      }
    };

    fetchBoardGames();
  }, []);

  useEffect(() => {
    setFilteredBoardGames(
      boardGames.filter((game) =>
        game.name.toLowerCase().includes(searchTerm.toLowerCase()) && !selectedGames.includes(game) && !barUser.ludotheque.includes(game.id)
      )
    );
  }, [searchTerm, boardGames]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSelect = (game) => {
    if (selectedGames.find((selectedGame) => selectedGame.id === game.id)) {
      
      setSelectedGames(
        selectedGames.filter((selectedGame) => selectedGame.id !== game.id)
      );
      //retirer le jeu de la liste des jeux afficher
      
    } else {
      setSelectedGames([...selectedGames, game]);
      setFilteredBoardGames(
        filteredBoardGames.filter((filteredGame) => filteredGame.id !== game.id)
      );
    }
  };

  const handleDelete = (game) => {
    setSelectedGames(
      selectedGames.filter((selectedGame) => selectedGame.id !== game.id)
    );
  };

  //=========
  const addBoardGames = async () => {
    try {
      for (const game of selectedGames) {
        await addBoardGame(barId, game.id);
      }
      updateBarUser();
      onClose();
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  };

  return (
    <Modal open={isOpen} onClose={onClose} className="custom-modal">
      <Modal.Header>Ajouter un Jeu</Modal.Header>
      <Modal.Content>
        <div id="modal-header">
          <Input
            icon="search"
            placeholder="Rechercher un jeu..."
            value={searchTerm}
            onChange={handleSearchChange}
            fluid
            id="search-input"
          />
          <button
            onClick={() => addBoardGames()}
            className="small_button_orange_background"
          >
            Valider
          </button>
        </div>
        <h3>Jeux selectionnés</h3>
        <div className="list-games">
          {selectedGames.map((game) => (
            <BoardGameCard boardGame={game} handleSelect={handleDelete} />
          ))}
        </div>
        <hr />

        {boardGames.length === 0 ? (
          <p>Chargement...</p>
        ) : (
          <div>
            <h3>Liste des jeux</h3>
            <div className="list-games">
              {filteredBoardGames.map((game) => (
                <BoardGameCard boardGame={game} handleSelect={handleSelect} />
              ))}
            </div>
          </div>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onClose}>Fermer</Button>
      </Modal.Actions>
    </Modal>
  );
};

export default BoardGameModal;

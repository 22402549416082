import BoardGame from "../models/boardgame_model";
import { firestore } from "./firebase";
import { doc, getDoc } from "firebase/firestore";

export const getBoardGameWithId = async (boardgameId) => {
    try {
      const docRef = doc(firestore, "boardgames", boardgameId);
      const docSnap = await getDoc(docRef);

      const result = BoardGame.fromFirestore(docSnap.data(), boardgameId);
      return result;
    } catch (error) {
      console.error("Error fetching document:", error);
    }
  };
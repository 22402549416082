// verify password format
export const verifyPasswordFormat = (password) => {
    var error = "";
    if(password.length < 8){
      error += "Le mot de passe doit contenir au moins 8 caractères \n";
    }
      if(!/[a-z]/.test(password)){
          error += "Le mot de passe doit contenir au moins une lettre minuscule \n";
      }
      if(!/[A-Z]/.test(password)){
        
          error += "Le mot de passe doit contenir au moins une lettre majuscule \n";
      }
      if(!/[0-9]/.test(password)){
          error += "Le mot de passe doit contenir au moins un chiffre \n";
      }
      if(!/[^a-zA-Z0-9]/.test(password)){
          error += "Le mot de passe doit contenir au moins un caractère spécial \n";
      }
    
    return error;
  };
import { firestore } from "../../services/firebase";
import { doc, getDoc, setDoc, collection, query, where, getDocs, updateDoc, deleteDoc, Timestamp, addDoc, GeoPoint, serverTimestamp } from "firebase/firestore";
import Papa from 'papaparse';

export const transferBarFromOldIdToNewId = async (oldId, newId) => {
  try {
    // Step 1: Retrieve the document from /bars/{oldId}
    const oldBarDocRef = doc(firestore, "bars", oldId);
    const oldBarDoc = await getDoc(oldBarDocRef);

    if (!oldBarDoc.exists()) {
      console.log(`Document with oldId ${oldId} does not exist.`);
      return;
    }

    // Step 2: Copy the data to a new document /bars/{newId} and update isSigned
    const newBarDocRef = doc(firestore, "bars", newId);
    const oldBarData = oldBarDoc.data();

    const updatedBarData = {
      ...oldBarData,
      isSigned: true, // Ensure isSigned is set to true
    };

    await setDoc(newBarDocRef, updatedBarData);
    console.log(`Document copied from /bars/${oldId} to /bars/${newId} with isSigned set to true.`);

    // Step 3: Update all documents in the 'events' collection where barId equals oldId
    const eventsCollectionRef = collection(firestore, "events");
    const eventsQuery = query(eventsCollectionRef, where("barId", "==", oldId));
    const eventsSnapshot = await getDocs(eventsQuery);

    const updatePromises = eventsSnapshot.docs.map(async (eventDoc) => {
      const eventDocRef = eventDoc.ref;
      await updateDoc(eventDocRef, { barId: newId });
      console.log(`Updated event ${eventDoc.id} with new barId ${newId}.`);
    });

    await Promise.all(updatePromises);
    console.log("All events updated successfully.");

    // Step 4: Delete the old document /bars/{oldId}
    await deleteDoc(oldBarDocRef);
    console.log(`Document with oldId ${oldId} has been deleted.`);
  } catch (error) {
    console.error("Error transferring bar data:", error);
  }
};
  
  export const addTestBar = async () => {
    try {
      // Référence à la collection "bars"
      const barsCollectionRef = collection(firestore, "bars");
  
      // Données placeholder pour le bar
      const placeholderData = {
        address: "2888 Waterview Lane, Albuquerque, New Mexico",
        bannerPicture: "https://firebasestorage.googleapis.com/v0/b/ludomit-89919.appspot.com/o/bars%2Fbar_banner.png?alt=media&token=73794a95-b7ea-4bd4-995f-a9b929ff8c88",
        bookings: [],
        city: "Albuquerque",
        country: "USA",
        createdAt: Timestamp.now(),
        description: "Description de test pour ce bar.",
        eventCreated: [],
        isAvailable: false,
        isSigned: false,
        isSubscribed: false,
        location: {
          lat: 35.059715,
          lng: -106.583084,
        }, // Peut être remplacé par un objet { lat: 0, lng: 0 }
        ludotheque: [],
        name: "Bar Test",
        nameLowercase: "bar test",
        notifications: [],
        phoneNumber: "",
        photos: [],
        profilePicture: "",
        reviews: [],
        schedule: {},
        socialMedia: [],
        website: "",
      };
  
      // Ajoute un nouveau document avec un ID généré automatiquement
      const docRef = await addDoc(barsCollectionRef, placeholderData);
  
      console.log("Bar de test ajouté avec succès ! ID :", docRef.id);
    } catch (error) {
      console.error("Erreur lors de l'ajout du bar de test : ", error);
    }
  };


  export const importBarsFromCSV = async () => {
    try {
        const response = await fetch('/assets/admin/bars_a_jeux.csv');
        const csvText = await response.text();
        Papa.parse(csvText, {
            header: true,
            delimiter: ";", // Delimiter is a comma
            quoteChar: '"', // Text indicator is a quotation mark
            complete: async (results) => {
                const data = results.data;
                for (let i = 0; i < 50; i++) {
                    const row = data[i];
                    try {
                      // Extraire les informations depuis la ligne
                      const name = row['Nom'] || '';
                      const address = row['Adresse'] || '';
                      const city = row['Ville'] || '';
                      const country = row['Pays'] || '';
                      const image = row['Image'] || '';
                      const phoneNumber = row['Téléphone'] || '';
                      const website = row['Site internet'] || '';
                      const description = row['Description'] || '';
                      const latitude = parseFloat(row['Latitude']) || 0.0;
                      const longitude = parseFloat(row['Longitude']) || 0.0;
              
                      // Préparer les données pour Firestore
                      const bar = {
                        name,
                        nameLowercase: name.toLowerCase(),
                        address,
                        city,
                        country,
                        description,
                        phoneNumber,
                        website,
                        isAvailable: true,
                        isSigned: false,
                        isSubscribed: false,
                        location: new GeoPoint(latitude, longitude),
                        photos: [image],
                        profilePicture: image,
                        bannerPicture:
                          'https://firebasestorage.googleapis.com/v0/b/ludomit-89919.appspot.com/o/bars%2Fbar_banner.png?alt=media&token=73794a95-b7ea-4bd4-995f-a9b929ff8c88',
                        ludotheque: [],
                        bookings: [],
                        eventCreated: [],
                        notifications: [],
                        reviews: [],
                        schedule: {},
                        socialMedia: [],
                        createdAt: serverTimestamp(),
                      };
                      console.log(bar);
                      return;
                      // Ajouter le bar dans Firestore
                      await addDoc(collection(firestore, 'bars'), bar);
                      console.log(`Bar ajouté : ${name}`);
                    } catch (error) {
                      console.error(`Erreur lors de l'ajout du bar : ${row['Nom']}`);
                      console.error(error);
                    }
                }
            },
            error: (error) => {
                console.error("Erreur lors de la lecture du fichier CSV: ", error);
            }
        });
    } catch (error) {
        console.error("Erreur lors du fetch du fichier CSV: ", error);
    }
};
import React from "react";
import './LegalNoticeScreen.css';

const LegalNoticeScreen = () => {

  return (
    <div className="legal-page-container">
      <h1 className="title">Mentions légales</h1>

      <p>
        L'application Ludomit est éditée par la société individuelle Lahoucine LAPUNAIRE, 
        immatriculée au RCS de Saint Denis sous le numéro 951388073, dont le siège social 
        est situé 9 Rue Charles Fourier, 91011 Évry-Courcouronnes.
      </p>
      <p>
        <strong>Numéro de Téléphone :</strong> 0652432127
      </p>
      <p>
        <strong>Adresse e-mail :</strong> contact@Ludomit.com
      </p>

      <h2>Directeur de publication</h2>
      <p>Le directeur de la publication est : Lahoucine LAPUNAIRE.</p>

      <h2>Hébergement</h2>
      <p>
        Les applications mobiles sont hébergées sur l'App Store (Apple) et Google Play (Android).
        Les deux plates-formes offrent une variété de fonctionnalités aux développeurs d'applications, notamment :
      </p>
      <ul>
        <li>Stockage et distribution des applications</li>
        <li>Gestion des téléchargements et des mises à jour</li>
        <li>Recueillir des commentaires et des évaluations</li>
        <li>Suivi des revenus</li>
      </ul>
      <p>
        Les développeurs d'applications peuvent soumettre leurs applications à l'App Store et à Google Play via un processus de révision. 
        Une fois l’application approuvée, elle est disponible au téléchargement par les utilisateurs.
      </p>
      <p>
        L'App Store et Google Play facturent aux développeurs une commission sur les revenus générés par leurs applications. 
        La commission est de 30 % pour les applications payantes et de 15 % pour les applications gratuites.
      </p>

      <h2>App Store</h2>
      <p><strong>Adresse :</strong> 1 Infinite Loop, Cupertino, CA 95014, United States</p>
      <p><strong>Numéro de Téléphone :</strong> 1-408-996-1010</p>

      <h2>Google Play</h2>
      <p><strong>Adresse :</strong> 1600 Amphitheatre Parkway, Mountain View, CA 94043, United States</p>
      <p><strong>Numéro de Téléphone :</strong> 1-650-253-0000</p>
    </div>
  );
};

export default LegalNoticeScreen;



import { doc, getDoc } from "firebase/firestore";
import { firestore } from "./firebase";

export const getUserWithId = async (userId) => {
  try {
    const docRef = doc(firestore, "users", userId);
    const docSnap = await getDoc(docRef);
    return docSnap.data();
  } catch (error) {
    console.error("Error fetching document:", error);
  }
};

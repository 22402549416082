import React from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';
import useBarAuthStore from "../../stores/bar_auth_store";

const BillingPortalButton = () => {
  const { barUser } = useBarAuthStore();
  const [loading, setLoading] = React.useState(false);

  const handleManageSubscription = async () => {
    setLoading(true);
    const functions = getFunctions();
    const createBillingPortalSession = httpsCallable(functions, 'createBillingPortalSession');
    try {
      const { data } = await createBillingPortalSession({ barId: barUser.id});
      window.location.href = data.url; // Redirect the user to the billing portal
      setLoading(false);
    } catch (error) {
      console.error('Error redirecting to billing portal:', error);
      // Optionally, show an error message to the user
    }
  };

  return (
    <button className="subscription-button" onClick={handleManageSubscription}>
      {loading ? 'Chargement...' : 'Gérer mon abonnement'}
    </button>
  );
};

export default BillingPortalButton;

// messageStore.js
import { create } from 'zustand';

const useMessageStore = create((set) => ({
  message: '',
  type: 'success',
  showMessage: (msg, type) => {
    set({ message: msg });
    set({ type: type });
    setTimeout(() => {
      set({ message: '' });
    }, 3000); // Affiche le message pendant 3 secondes
  },
}));

export default useMessageStore;

import React, { useEffect, useState } from "react";
import "./BarLandingScreen.css"; // Assurez-vous d'ajouter les styles CSS
import { Icon } from "semantic-ui-react";
import logo from "../../assets/logo_no_bg.png"; // Logo de Ludomit
import { sendContactEmail } from "../../services/email";

const BarLandingScreen = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState("");

  const scrollToSection = (index) => {
    const sections = document.querySelectorAll(".section");
    if (index >= 0 && index < sections.length) {
      sections[index].scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    let currentSection = 0;
    const sections = document.querySelectorAll(".section");
    const handleScroll = (event) => {
      if (event.deltaY > 0) {
        // Scrolling down
        if (currentSection < sections.length - 1) {
          currentSection++;
          scrollToSection(currentSection);
        }
      } else {
        // Scrolling up
        if (currentSection > 0) {
          currentSection--;
          scrollToSection(currentSection);
        }
      }
    };

    window.addEventListener("wheel", handleScroll);
    return () => {
      window.removeEventListener("wheel", handleScroll);
    };
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault(); // Empêche le rechargement de la page

    if (!email || !message || !name) {
      setStatus("Tous les champs sont obligatoires.");
      return;
    }

    try {
      sendContactEmail(email, message);
      setStatus("Votre message a été envoyé avec succès !");
      /*
      const success = await sendContactEmail(email, message);
      if (success) {
        setStatus("Votre message a été envoyé avec succès !");
        setName(""); // Réinitialise le formulaire
        setEmail("");
        setMessage("");
      } else {
        setStatus("Une erreur s'est produite lors de l'envoi.");
      }
        */
    } catch (error) {
      setStatus("Une erreur inattendue s'est produite.");
      console.error(error);
    }
  };

  return (
    <div className="bar-landing-page">
      {/* Header */}
      <header className="header">
        <div className="header-content">
        <a href="/">
          <img src={logo} alt="LudoMit" className="logo" />
          </a>
          <div className="header-buttons">
            <a href="/bar/login" className="header-text-button">
              Connexion
            </a>
            <a href="/bar/signup" className="header-button">
              Inscription
            </a>
          </div>
        </div>
      </header>

      {/* Landing Section */}
      <section className="section bar-landing-section">
        <h1 className="bar-title">Boostez votre bar à jeux avec Ludomit</h1>
        <h3>
          Rejoignez notre plateforme et offrez une expérience inégalée à vos
          clients tout en optimisant la gestion de votre établissement.
        </h3>
        <button className="scroll-arrow" onClick={() => scrollToSection(1)}>
          <Icon name="angle down" size="big" />
        </button>
      </section>

      {/* Services Section */}
      <section className="section services-section">
        <h2>Nos services pour les bars</h2>
        <div className="services-grid">
          <div className="service-item">
            <Icon name="chart bar" size="big" />
            <h3>Analytics détaillées</h3>
            <p>
              Suivez les statistiques de fréquentation, le succès des événements
              organisés, et l'engagement de vos clients grâce à des tableaux de
              bord intuitifs.
            </p>
          </div>
          <div className="service-item">
            <Icon name="calendar check outline" size="big" />
            <h3>Système de réservation</h3>
            <p>
              Facilitez la gestion de vos tables grâce à notre système de
              réservation intuitif. Optimisez l'occupation et évitez les
              doubles réservations.
            </p>
          </div>
          <div className="service-item">
            <Icon name="settings" size="big" />
            <h3>Paramétrage personnalisé</h3>
            <p>
              Configurez votre espace bar selon vos besoins : nombre de tables,
              jeux disponibles, et autres spécificités de votre établissement.
            </p>
          </div>
        </div>
        <button className="scroll-arrow" onClick={() => scrollToSection(2)}>
          <Icon name="angle down" size="big" />
        </button>
      </section>

      {/* Pricing Section */}
      <section className="section pricing-section">
        <h2>Tarifs</h2>
        <div className="pricing-card">
          <h3>Abonnement Pro</h3>
          <p>
            Profitez de toutes les fonctionnalités avancées pour seulement
            <strong> 30€ par mois</strong>.
          </p>
          <p className="validity">Offre valable pour toute l'année 2025.</p>
        </div>
        <button className="scroll-arrow" onClick={() => scrollToSection(3)}>
          <Icon name="angle down" size="big" />
        </button>
      </section>

      {/* Contact Section */}
      <section className="section contact-section">
        <div className="contact-header">
          <h2>Contactez-nous</h2>
          <p>
            Vous souhaitez en savoir plus ou souscrire à l'abonnement ?
            Contactez-nous !
          </p>
        </div>

        <div className="contact-content">
          {/* Formulaire de contact */}
          <form className="contact-form" onSubmit={handleSubmit}>
            <div className="form-group">
              <input
                type="text"
                placeholder="Nom"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <textarea
                placeholder="Message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                required
              ></textarea>
            </div>
            <button type="submit" className="submit-button">
              Envoyer
            </button>
            {status && <p className="status-message">{status}</p>}
          </form>

          {/* Informations de contact */}
          <div className="contact-info">
            <h3>Contact direct</h3>
            <p>
              Email :{" "}
              <a href="mailto:contact@ludomit.com">contact@ludomit.com</a>
            </p>
            <p>Suivez-nous :</p>
            <div className="social-icons">
              <a
                href="https://facebook.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon name="facebook" size="big" />
              </a>
              <a
                href="https://twitter.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon name="twitter" size="big" />
              </a>
              <a
                href="https://instagram.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon name="instagram" size="big" />
              </a>
              <a
                href="https://linkedin.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon name="linkedin" size="big" />
              </a>
            </div>
          </div>
        </div>
        <button className="scroll-arrow" onClick={() => scrollToSection(0)}>
          <Icon name="angle up" size="big" />
        </button>
      </section>

      {/* Footer */}
      <footer className="footer">
        <div className="footer-content">
          <p>© 2023 Ludomit. Tous droits réservés.</p>
          <div className="footer-links">
            <a href="/terms-of-services">CGU</a>
            <a href="/legal-notice">Mentions Légales</a>
            <a href="/privacy-policy">Politique de Confidentialité</a>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default BarLandingScreen;
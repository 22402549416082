// Dashboard.js
import React, { useEffect, useState } from "react";
import "./BoarGameCard.css";
import { getBoardGameWithId } from "../../services/boardgames";
import { Icon } from "semantic-ui-react";

const BoardGameCard = ({
  boardGameId,
  handleSelect,
  boardGame,
  handleDelete,
}) => {
  const [selectedBoardGame, setBoardGame] = useState({}); //[boardGame, setBoardGame
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (boardGameId !== "" && boardGame == null) {
      getBoardGameWithId(boardGameId).then((result) => {
        setBoardGame(result);
      });
    } else {
      setBoardGame(boardGame);
    }
    setLoading(false);
  }, [boardGameId, boardGame]);

  const getRating = () => {
    try {
      return selectedBoardGame.reviews.value.toFixed(1);
    } catch (error) {
      return 0;
    }
  };

  const getCategories = () => {
    try {
      var categories = "";
      selectedBoardGame.categories.forEach((category) => {
        categories += category + ", ";
      });
      categories = categories.slice(0, -2);
      categories =
        categories.length > 30
          ? categories.substring(0, 30) + "..."
          : categories;
      return categories;
    } catch (error) {
      return "Pas de catégories";
    }
  };

  if (loading) {
    return (
      <li>
        <p>loading ...</p>
      </li>
    );
  } else {
    return (
      <div
        className="game-card"
        onClick={() => handleSelect(selectedBoardGame)}
      >
        <img
          src={selectedBoardGame.imageUrl}
          alt={selectedBoardGame.name}
          className="boardgame-image"
        />
        <div className="game-info">
          <h2>{selectedBoardGame.name}</h2>

          <h4>{getCategories()}</h4>
          <h4>
            {selectedBoardGame.minPlayers} - {selectedBoardGame.maxPlayers}{" "}
            joueurs
          </h4>
          <h4>{getRating()}</h4>
        </div>
        {handleDelete && (
          <Icon
            size="large"
            name="trash"
            color="red"
            onClick={() => handleDelete(selectedBoardGame.id)}
          />
        )}
      </div>
    );
  }
};

export default BoardGameCard;

import { React, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import BarRoutes from "./components/routes/BarRoutes";
import HomeScreen from "./screens/home/HomeScreen";
import TestScreen from "./screens/test/test";
import "./App.css";
import MessageBanner from "./components/message";
import ResetPasswordScreen from "./screens/auth/ResetPasswordScreen";
import AccountNotActivatedScreen from "./screens/messages/AccountNotActivatedScreen";
import AccountNotSubscribedScreen from "./screens/messages/AccountNotSubscribedScreen";
import useBarAuthStore from "./stores/bar_auth_store";
import { auth } from "./services/firebase";
import AdminRouter from "./admin/routes/AdminRouter";
import SupportScreen from "./screens/general/SupportScreen";
import LegalNoticeScreen from "./screens/legales/LegalNoticeScreen";
import TermsOfServicesScreen from "./screens/legales/TermsOfServicesScreen";
import PrivacyPolicyScreen from "./screens/legales/PrivacyPolicyScreen";
import BarLandingScreen from "./screens/home/BarLandingScreen";

const { getIsAuthenticated } = useBarAuthStore.getState();

const App = () => {
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        getIsAuthenticated(true);
      } else {
        getIsAuthenticated(false);
      }
    });

    return () => unsubscribe();
  }, []);

  return (
    <Router>
      <MessageBanner />
      <Routes>
        <Route path="/" element={<HomeScreen />} />
        <Route path="/bar-services" element={<BarLandingScreen />} />
        <Route path="/legal-notice" element={<LegalNoticeScreen />} />
        <Route path="/terms-of-services" element={<TermsOfServicesScreen />} />
        <Route path="/privacy-policy" element={<PrivacyPolicyScreen />} />
        <Route path="/bar/*" element={<BarRoutes />} />
        <Route path="/admin/*" element={<AdminRouter/>} />
        <Route path="/support" element={<SupportScreen/>} />
        <Route path="/test" element={<TestScreen />} />
        <Route path="/reset-password" element={<ResetPasswordScreen />} />
        <Route
          path="/account-not-activated"
          element={<AccountNotActivatedScreen />}
        />
        <Route path="/subscription" element={<AccountNotSubscribedScreen />} />

        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Router>
  );
};

export default App;

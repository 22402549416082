import { firestore } from '../../services/firebase';
import Papa from 'papaparse';
import { collection, addDoc } from 'firebase/firestore';

export const importBoardgames = async () => {
    try {
        const response = await fetch('/assets/admin/boardgames.csv');
        const csvText = await response.text();
        Papa.parse(csvText, {
            header: true,
            delimiter: ",", // Delimiter is a comma
            quoteChar: '"', // Text indicator is a quotation mark
            complete: async (results) => {
                const data = results.data;
                for (let i = 0; i < 50; i++) {
                    const row = data[i];
                    console.log(row);
                    try {
                        await addDoc(collection(firestore, 'boardgames'), {
                            name: row['name'],
                            nameLowercase: row['name'].toLowerCase(),
                            age: parseInt(row['age']),
                            minPlayers: parseInt(row['min_players']),
                            maxPlayers: parseInt(row['max_players']),
                            publishers: row['publisher'].split(', '),
                            designers: row['designer'].split(', '),
                            author: row['author'].split(', '),
                            categories: row['category'].split(', '),
                            mechanisms: row['mechanic'].split(', '),
                            minPlaytime: parseInt(row['min_playing_time']),
                            maxPlaytime: parseInt(row['max_playing_time']),
                            yearPublished: parseInt(row['year_published']),
                            isExpansion: row['is_extension'] === 'True',
                            image: row['image'],
                            link: row['link'],
                            rating: [{
                                name: "BoardGameGeek",
                                value: parseFloat(row['rate']),
                                image: "https://upload.wikimedia.org/wikipedia/commons/thumb/b/bb/BoardGameGeek_Logo.svg/1200px-BoardGameGeek_Logo.svg.png"
                            }],
                            averageRating: parseFloat(row['rate']),
                        });
                        console.log(`Ligne ${i + 1} ajoutée avec succès`);
                    } catch (error) {
                        console.error(`Erreur lors de l'ajout de la ligne ${i + 1}: `, error);
                    }
                }
            },
            error: (error) => {
                console.error("Erreur lors de la lecture du fichier CSV: ", error);
            }
        });
    } catch (error) {
        console.error("Erreur lors du fetch du fichier CSV: ", error);
    }
};
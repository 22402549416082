import { React } from "react";
import useBarAuthStore from "../../stores/bar_auth_store";
import { Icon } from "semantic-ui-react";
import { logout } from "../../services/auth";

const BarAfterSignupScreen = () => {
  const { email } = useBarAuthStore((state) => ({
    email: state.email,
    getEmail: state.setEmail,
  }));

  const handleDownload = () => {
    const filePath = require("../../assets/test/test.pdf"); // Charger le fichier
    const link = document.createElement("a"); // Crée un élément <a>
    link.href = filePath; // Définit le chemin du fichier
    link.download = "test.pdf "; // Définit le nom du fichier pour le téléchargement
    document.body.appendChild(link); // Ajoute le lien temporairement au DOM
    link.click(); // Simule un clic pour déclencher le téléchargement
    document.body.removeChild(link); // Supprime le lien du DOM après le téléchargement
  };


  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        flexDirection: "column",
        backgroundColor: "#f9f9f9",
        padding: "20px",
        fontFamily: "'Arial', sans-serif",
      }}
    >
      <h1
        style={{
          color: "#4CAF50",
          fontSize: "2.5em",
          marginBottom: "20px",
        }}
      >
        Merci pour votre inscription ! 😁
      </h1>

      <h2
        style={{
          color: "#333",
          textAlign: "center",
          marginBottom: "20px",
        }}
      >
        Nous avons bien reçu votre inscription. Vous pouvez dès maintenant
        vérifier votre email. De plus, vous allez bientôt être contacté sur
        votre email :
      </h2>

      <h3
        style={{
          marginBottom: "20px",
        }}
      >
        {email}
      </h3>
      <h2
        style={{
          color: "#333",
          textAlign: "center",
          marginBottom: "20px",
        }}
      >
        En attandant, vous pouvez télecharger le formulaire à remplir et reunir
        les documents nécessaires pour finaliser votre inscription.
      </h2>
      <button
          className="download-button"
          onClick={handleDownload}
          style={{
            backgroundColor: "#FFB459",
            color: "#fff",
            padding: "10px 20px",
            borderRadius: "12px",
            border: "none",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Icon name="download" size="large" />
          Télécharger le PDF
        </button>
      <h2
        style={{
          color: "#333",
          textAlign: "center",
          marginBottom: "20px",
        }}
      >
        Si vous ne recevez pas d'email de notre part, vous pouvez nous contacter
        via l'email suivant :
      </h2>
      <div
        className="contact_email"
        onClick={() => {
          navigator.clipboard.writeText("contact@ludomit.com");
        }}
      >
        <h3
          style={{
            marginBottom: "20px",
          }}
        >
          contact@ludomit.com
        </h3>
        <Icon name="copy" size="large" />
      </div>

      <h2
        style={{
          color: "#333",
          textAlign: "center",
        }}
      >
        À bientôt !
      </h2>
      <button
        className="small_button_orange_background"
        type="submit"
        onClick={() => {
          logout();
        }}
      >
        Se deconnecter
      </button>
    </div>
  );
};

export default BarAfterSignupScreen;

import { React, useState } from "react";
import { login } from "../../services/auth";


const BarLoginScreen = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  return (
    <div id="BarSingupScreen" style={
      {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        width: "100vw",
      }
    
    }>
      <h1>Section Bar</h1>
      <h2 style={{
        textAlign: "center"
      }}>Bon retour, Connectez-vous et accédez à votre espace</h2>
        <label htmlFor="email">Email</label>
        <input
        className="input"
          type="email"
          id="email"
          name="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <br/>
        <label htmlFor="password">Mot de passe</label>
        <input
        className="input"
          type="password"
          id="password"
          name="password"
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
          required
        />
        <button className="small_button_transparent_background" onClick={()=> window.location.href = process.env.REACT_APP_URL_PREFIX + "/reset-password"}>Mot de passe oublié ?</button>
        <br/>
        <button className="small_button_orange_background" type="submit" onClick={()=>{login(email,password)}}>Connexion</button>
        <button className="small_button_transparent_background" onClick={()=> window.location.href = process.env.REACT_APP_URL_PREFIX + "/bar/signup"}>Pas de compte ? Inscrivez vous</button>
    </div>
  );
};

export default BarLoginScreen;

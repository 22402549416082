import React from "react";
import './TermsOfServicesScreen.css';

const TermsOfServicesScreen = () => {

  return (
    <div className="terms-page-container">
      <h1 className="title">Termes et conditions d'utilisation</h1>

      <section>
        <h2>Article 1 – Objet des conditions générales d'utilisation</h2>
        <p>
          Les présentes conditions générales d'utilisation (ci-après dénommées
          « CGU ») ont pour objet de définir les règles d'utilisation de
          l'application mobile « Ludomit » (ci-après dénommée « Application »)
          éditée par la société individuelle Lahoucine LAPUNAIRE, immatriculée
          au Registre du Commerce et des Sociétés de Saint Denis sous le numéro
          951388073, dont le siège social est situé 9 Rue Charles Fourier, 91011
          Évry-Courcouronnes. Le directeur de la publication est Lahoucine
          LAPUNAIRE, Président : contact@Ludomit.com
        </p>
        <p>
          En installant l'Application sur votre terminal et/ou en accédant à
          l'Application, vous acceptez sans réserve l'intégralité des présentes
          CGU et vous vous engagez à respecter les obligations qui vous
          incombent. Si vous n’acceptez pas les CGU ou avez des réserves, merci
          de ne pas utiliser l’Application.
        </p>
        <p>
          Les CGU expriment l'intégralité de l'accord entre vous et Ludomit
          applicable à votre utilisation de l'Application. Ludomit se réserve le
          droit de modifier les présentes CGU en les mettant à jour à tout
          moment. Nous vous conseillons de consulter cette page régulièrement.
        </p>
      </section>

      <section>
        <h2>Article 2 - Accès à l'application</h2>
        <p>
          Pour accéder à l’Application, vous devez disposer d’un appareil mobile
          et d’un accès à Internet. L'Application est téléchargeable
          gratuitement depuis les plateformes « Apple Store » et « Google Play
          Store ».
        </p>
        <ul>
          <li>iPhone® exécutant iOS 10.0 ou supérieur</li>
          <li>Appareils Android® Lollipop (API 21) ou supérieur</li>
        </ul>
        <p>
          Une fois l’Application installée sur votre appareil, connectez-vous
          simplement en utilisant votre adresse e-mail. Si vous êtes membre
          Ludomit, vous recevrez une notification pour valider votre compte.
        </p>
      </section>

      <section>
        <h2>Article 3 - Utilisation de l'application</h2>
        <p>
          Ludomit vous concède un droit personnel d'utilisation de l'Application
          et de son contenu. Ce droit est concédé à titre non exclusif,
          révocable, incessible, mondial et gratuit pour le seul usage de
          l'Application.
        </p>
        <p>
          Il est strictement interdit d'accéder et/ou d'utiliser et/ou de
          tenter d'accéder ou d'utiliser les codes sources ou les objets de
          l'Application. Vous n'acquérez aucun droit de propriété intellectuelle
          sur l'Application ou son contenu.
        </p>
      </section>

      <section>
        <h2>Article 4 - Données personnelles</h2>
        <p>
          Les données personnelles utilisées incluent votre numéro de
          téléphone, votre adresse e-mail, votre photo de profil, vos messages
          et publications. Vous pouvez exercer votre droit d'accès et de
          rectification en envoyant un e-mail à contact@Ludomit.com.
        </p>
      </section>

      <section>
        <h2>Article 5 - Propriété intellectuelle</h2>
        <p>
          L'ensemble du contenu de l'Application relève de la législation
          française et internationale sur le droit d'auteur. Tous les droits de
          reproduction et de représentation relatifs à l'Application sont
          réservés par Ludomit.
        </p>
      </section>

      <section>
        <h2>Article 6 - Disponibilité de l'application</h2>
        <p>
          L'Application est accessible en ligne 7j/7 et 24h/24. Ludomit se
          réserve le droit de cesser, sans préavis, de fournir tout ou partie du
          Service ou des fonctionnalités de l'Application.
        </p>
      </section>

      <section>
        <h2>Article 7 - Responsabilité</h2>
        <p>
          Ludomit met en œuvre tous les moyens nécessaires pour assurer le
          meilleur accès possible à l'Application. Vous êtes seul responsable de
          vos identifiants et de l'utilisation de l'Application.
        </p>
      </section>

      <section>
        <h2>Article 8 - Non renonciation</h2>
        <p>
          Le fait pour l'une des parties de ne pas profiter d'un manquement par
          l'autre partie à l'une des obligations mentionnées dans les présentes
          CGU ne pourra pas être interprété comme une renonciation.
        </p>
      </section>

      <section>
        <h2>Article 9 - Loi applicable et litiges</h2>
        <p>
          Les présentes CGU sont régies par le droit français. Tout litige
          concernant l'Application ou l'interprétation des présentes CGU sera
          soumis au tribunal compétent de Lyon.
        </p>
      </section>

      <section>
        <h2>Article 10 - Contact</h2>
        <p>
          Pour toute question, contactez-nous à l'adresse suivante :
          contact@Ludomit.com.
        </p>
      </section>
    </div>
  );
};

export default TermsOfServicesScreen;



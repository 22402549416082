import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import AdminDashboardScreen from "../screens/AdminDashboardScreen";
import AdminLoginScreen from "../screens/AdminLoginScreen";

const BarRoutes = () => {

    return (
      <Routes>
        <Route path="/" element={<AdminLoginScreen/>} />
        <Route path="/dashboard" element={<AdminDashboardScreen/>} />
        <Route path="*" element={<Navigate to="/" />} />      
      </Routes>
    );
  };
  
  export default BarRoutes;
  
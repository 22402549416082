// BarLoadingScreen.js
import React, { useEffect } from 'react';
import {getAuthState} from '../../services/auth'; // Assurez-vous que le chemin est correct
import './BarLoadingScreen.css'; // Assurez-vous d'importer le fichier CSS pour les styles
import { verifyBarState } from '../../services/bars'; // Assurez-vous que le chemin est correct

const BarLoadingScreen = () => {

  useEffect(() => {
    setTimeout(async () => {
        const isLogged = getAuthState();
        const isSubscribed = await verifyBarState();
        if (isLogged) {
          window.location.href = process.env.REACT_APP_URL_PREFIX + "/bar/dashboard";
        } 
        else if (isLogged && isSubscribed === "Bar is not signed") {
          window.location.href = process.env.REACT_APP_URL_PREFIX + "/bar/signup";
        }
        else {
          window.location.href = process.env.REACT_APP_URL_PREFIX + "/bar/login";
        } 
    }, 500);
  }, []);

  return (
    <div className="loading-container">
      <div className="spinner"></div>
      <p>Chargement ...</p>
    </div>
  );
};

export default BarLoadingScreen;

import { create } from 'zustand';
import { persist } from 'zustand/middleware';

const useAdminStore = create(
  persist(
    (set) => ({
        session: null,
        setSession: (session) => set({ session }),
    }),
    {
      name: 'admin-storage', // Nom sous lequel l'état sera stocké dans le localStorage
      getStorage: () => sessionStorage, // Utilise localStorage pour persister l'état
    }
  )
);

export default useAdminStore;
import React, { useState } from "react";
import useBarAuthStore from "../../stores/bar_auth_store";
import useMessageStore from "../../stores/message_store";
import {
  updateBarField,
  uploadBannerPicture,
  uploadProfilePicture,
  uploadPhotos,
} from "../../services/bars";
import "./EditBarProfileScreen.css";
import { FaEdit, FaTrash, FaPlus } from "react-icons/fa";
import { Icon } from "semantic-ui-react";

const EditBarProfileScreen = () => {
  const { barUser, updateBarUser } = useBarAuthStore();
  const { showMessage } = useMessageStore();

  // States for all editable fields
  const [name, setName] = useState(barUser.name || "");
  const [description, setDescription] = useState(barUser.description || "");
  const [bannerPicture, setBannerPicture] = useState(
    {
      file: null,
      preview: barUser.bannerPicture || "",
    } || ""
  );
  const [profilePicture, setProfilePicture] = useState(
    {
      file: null,
      preview: barUser.profilePicture || "",
    } || ""
  );
  const [photos, setPhotos] = useState(
    (barUser.photos || []).map((photo) => ({ file: null, preview: photo }))
  );
  const [address, setAddress] = useState(barUser.address || "");
  const [city, setCity] = useState(barUser.city || "");
  const [country, setCountry] = useState(barUser.country || "");
  const [phoneNumber, setPhoneNumber] = useState(barUser.phoneNumber || "");
  const [website, setWebsite] = useState(barUser.website || "");

  const handleSave = async () => {
    try {
      // Vérification et mise à jour des champs de texte simples
      console.log("Sauvegarde des modifications du profil...");
      const updates = {
        name,
        description,
        address,
        city,
        country,
        phoneNumber,
        website,
      };
      
      for (const [key, value] of Object.entries(updates)) {
        if (barUser[key] !== value) {
          await updateBarField(barUser.id, key, value);

          // Si le nom est modifié, mettre à jour également le champ nameLowercase
          if (key === "name") {
            const lowercaseName = value.toLowerCase();
            if (barUser.nameLowercase !== lowercaseName) {
              await updateBarField(barUser.id, "nameLowercase", lowercaseName);
            }
          }
        }
      }

      // Vérifie et met à jour la bannière si elle a changé
      if (bannerPicture && bannerPicture.file !== null) {
        const bannerUrl = await uploadBannerPicture(
          barUser.id,
          barUser.userId,
          bannerPicture.file
        );
        if (bannerUrl && bannerUrl !== barUser.bannerPicture) {
          await updateBarField(barUser.id, "bannerPicture", bannerUrl);
        }
      }

      // Vérifie et met à jour la photo de profil si elle a changé
      if (profilePicture && profilePicture.file !== null) {
        const profileUrl = await uploadProfilePicture(
          barUser.id,
          barUser.userId,
          profilePicture.file
        );
        if (profileUrl && profileUrl !== barUser.profilePicture) {
          await updateBarField(barUser.id, "profilePicture", profileUrl);
        }
      }

      // Vérifie et met à jour les photos si elles ont changé
      if (photos.some((photo) => photo.file)) {
        // Upload uniquement les nouvelles photos (celles avec un fichier)
        const filesToUpload = photos
          .filter((photo) => photo.file) // Sélectionne uniquement les nouvelles photos
          .map((photo) => photo.file);
      
        const uploadedPhotos = await uploadPhotos(barUser.id, barUser.userId, filesToUpload);
      
        // Concatène les URLs existantes avec les nouvelles photos uploadées
        const updatedPhotos = photos.map((photo) =>
          photo.file ? uploadedPhotos.shift() : photo.preview
        );
      
        // Vérifie si les photos ont changé avant de mettre à jour Firestore
        if (JSON.stringify(updatedPhotos) !== JSON.stringify(barUser.photos)) {
          await updateBarField(barUser.id, "photos", updatedPhotos);
        }
      } else {
        console.log("Aucune nouvelle photo à uploader.");
      }

      // Met à jour les données locales après les modifications
      await updateBarUser();
      showMessage("Profil mis à jour avec succès", "success");
      setTimeout(() => {
        window.location.href = "/bar/profile";
      }, 1000);
    } catch (error) {
      console.error("Erreur lors de la sauvegarde du profil :", error);
      showMessage(
        "Une erreur est survenue lors de la mise à jour du profil",
        "error"
      );
    }
  };

  const handleMultiplePhotoAdd = (files) => {
    const newPhotos = Array.from(files).map((file) => ({
      file,
      preview: URL.createObjectURL(file),
    }));
    setPhotos([...photos, ...newPhotos]);
  };

  const handlePhotoRemove = (index) => {
    const updatedPhotos = photos.filter((_, i) => i !== index);
    setPhotos(updatedPhotos);
  };

  const handlePictureUpload = (file, setter) => {
    setter({ file, preview: URL.createObjectURL(file) });
  };

  return (
    <div className="edit-bar-profile">
      <div className="bar-header">
        <Icon name="info circle" size="large" color="blue" />
        <h4>Modifier votre profil.</h4>
      </div>
      {/* Bannière */}
      <div className="banner-edit">
        <img
          src={bannerPicture?.preview || "https://via.placeholder.com/1200x300"}
          alt="Bannière"
          className="banner-image"
        />
        <label className="upload-banner-icon">
          <FaEdit color="white" />
          <input
            type="file"
            accept="image/*"
            onChange={(e) => handlePictureUpload(e.target.files[0], setBannerPicture)}
          />
        </label>
      </div>
      {/* Photo de profil */}
      <div className="profile-picture-container">
        <img
          src={profilePicture?.preview || "https://via.placeholder.com/150"}
          alt="profil"
          className="profile-picture"
        />
        <label className="upload-icon">
          <FaEdit color="white" />
          <input
            type="file"
            accept="image/*"
            onChange={(e) => handlePictureUpload(e.target.files[0], setProfilePicture)}
          />
        </label>
      </div>
      {/* Informations générales */}
      <div className="section-edit-profile">
        <h2>Informations générales</h2>
        <div className="section-edit-profile-sub-div">
          <label>Nom</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="input"
          />
          <label>Description</label>
          <textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className="input textarea"
          />
        </div>
      </div>
      {/* Photos du bar */}
      <div className="section-edit-profile">
        <h2>Photos du bar</h2>
        <div className="photo-gallery">
          {photos.map((photo, index) => (
            <div key={index} className="photo-item">
              <div className="photo-wrapper">
                <img src={photo?.preview} alt={`${index + 1}`} />
                <button
                  className="remove-button"
                  onClick={() => handlePhotoRemove(index)}
                >
                  <FaTrash />
                </button>
              </div>
            </div>
          ))}

          {/* Add photos button */}
          <div className="photo-item add-photo-button">
            <label className="photo-placeholder">
              <FaPlus />
              <input
                type="file"
                accept="image/*"
                multiple
                onChange={(e) => handleMultiplePhotoAdd(e.target.files)}
                style={{ display: "none" }}
              />
            </label>
          </div>
        </div>
      </div>
      {/* Adresse et Contact */}
      <div className="dual-section-edit-profile">
        {/* Adresse */}
        <div className="section-edit-profile-sub-div">
          <h2>Adresse</h2>
          <label>Adresse</label>
          <input
            type="text"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            className="input"
          />
          <label>Ville</label>
          <input
            type="text"
            value={city}
            onChange={(e) => setCity(e.target.value)}
            className="input"
          />
          <label>Pays</label>
          <input
            type="text"
            value={country}
            onChange={(e) => setCountry(e.target.value)}
            className="input"
          />
        </div>
        {/* Contact */}
        <div className="section-edit-profile-sub-div">
          <h2>Contact</h2>
          <label>Téléphone</label>
          <input
            type="text"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            className="input"
          />
          <label>Site Web</label>
          <input
            type="text"
            value={website}
            onChange={(e) => setWebsite(e.target.value)}
            className="input"
          />
        </div>
      </div>
      <br />
      <div className="profile-actions">
        <button className="edit-button" onClick={handleSave}>
          Sauvegarder
        </button>
        <button
          className="cancel-button"
          onClick={() => (window.location.href = "/bar-profile")}
        >
          Annuler
        </button>
      </div>
    </div>
  );
};

export default EditBarProfileScreen;

import { doc, getDoc, updateDoc } from "firebase/firestore";
import { firestore } from "../../services/firebase";
import { getHash } from "../utils/session";
import { generateUniqueString } from "../utils/session";
import  useAdminStore  from "../stores/admin_store";
import  useMessageStore  from "../../stores/message_store";

export const login = async (password) => {
  const hashedPassword = await getHash(password);
  console.log(hashedPassword);
  const adminRef = doc(firestore, "admins", "admin");
  const adminDoc = await getDoc(adminRef);

  if (adminDoc.exists()) {
    const adminData = adminDoc.data();
    if (adminData.password === hashedPassword) {
      const uniqueSession = generateUniqueString();
      await updateDoc(adminRef, { session: uniqueSession });

      const { setSession } = useAdminStore.getState();
      setSession(uniqueSession);

      const { showMessage } = useMessageStore.getState();
      showMessage("Connexion réussie", "success");

      // Redirection vers /admin/dashboard
      window.location.href = "/admin/dashboard";
    } else {
      const { showMessage } = useMessageStore.getState();
      showMessage("Mot de passe incorrect", "error");
    }
  } else {
    const { showMessage } = useMessageStore.getState();
    showMessage("Mot de passe incorrect", "error");
  }
};

export const verifySession = async () => {
    const adminRef = doc(firestore, "admins", "admin");
    const adminDoc = await getDoc(adminRef);
    
    if (adminDoc.exists()) {
        const adminData = adminDoc.data();
        const sessionData  = adminData.session;
        const { session } = useAdminStore.getState();
        if (session !== sessionData) {
            window.location.href = "/admin";
        }
    }
}
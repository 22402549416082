import {
  eachDayOfInterval,
  eachMonthOfInterval,
  endOfMonth,
  format,
  startOfMonth,
  subDays,
  subMonths,
  differenceInDays,
} from "date-fns";
import { getBoardGameWithId } from "../services/boardgames";
import { saveAs } from "file-saver";

export const formatEventsForLineChart = (events, range = "30days") => {
  let dateInterval;
  if (range === "12months") {
    const today = new Date();
    const startDate = subMonths(today, 11);
    dateInterval = eachMonthOfInterval({
      start: startOfMonth(startDate),
      end: endOfMonth(today),
    });
  } else {
    const days = range === "7days" ? 7 : 30;
    const today = new Date();
    const startDate = subDays(today, days);
    dateInterval = eachDayOfInterval({ start: startDate, end: today });
  }

  const eventCounts = {};
  events.forEach((event) => {
    const eventDate = new Date(
      event.date.seconds * 1000 + event.date.nanoseconds / 1000000
    );
    const formattedDate =
      range === "12months"
        ? format(eventDate, "yyyy-MM")
        : format(eventDate, "yyyy-MM-dd");

    if (!eventCounts[formattedDate]) {
      eventCounts[formattedDate] = 0;
    }
    eventCounts[formattedDate]++;
  });

  const data = dateInterval.map((date) => {
    const formattedDate =
      range === "12months"
        ? format(date, "yyyy-MM")
        : format(date, "yyyy-MM-dd");
    return {
      name: formattedDate,
      reservations: eventCounts[formattedDate] || 0,
    };
  });

  return data;
};

export const calculateEventVariation = (events) => {
  // Créer un tableau pour stocker le nombre d'événements par jour
  const eventCounts = new Array(61).fill(0); // Tableau de 61 jours initialisé à 0

  // Parcourir chaque événement
  events.forEach((event) => {
    // Convertir le Timestamp en objet Date
    const eventDate = new Date(
      event.date.seconds * 1000 + event.date.nanoseconds / 1000000
    );
    const daysAgo = differenceInDays(new Date(), eventDate);

    // Si l'événement a été créé il y a 60 jours ou moins, incrémenter le compteur correspondant
    if (daysAgo <= 60) {
      eventCounts[60 - daysAgo]++;
    }
  });

  // Calculer le nombre d'événements des 30 derniers jours
  const eventsLast30Days = eventCounts.slice(31, 61).reduce((a, b) => a + b, 0);
  const eventsPrevious30Days = eventCounts
    .slice(0, 30)
    .reduce((a, b) => a + b, 0);

  // Calculer la variation en pourcentage
  const variation =
    ((eventsLast30Days - eventsPrevious30Days) / (eventsPrevious30Days || 1)) *
    100;

  // Ajouter le signe "+" ou "-" selon la variation
  const variationString =
    variation >= 0 ? `+${variation.toFixed(1)}%` : `${variation.toFixed(1)}%`;

  // Retourner la variation
  return variationString;
};

export const getBookingVariations = (events) => {
  const today = new Date();
  const startDate = subDays(today, 30);
  const startDatePrev = subDays(today, 60);

  let countCurrent = 0;
  let countPrevious = 0;

  events.forEach((event) => {
    const eventDate = new Date(
      event.date._seconds * 1000 + event.date._nanoseconds / 1000000
    );
    if (eventDate >= startDate && eventDate <= today) {
      countCurrent++;
    } else if (eventDate >= startDatePrev && eventDate < startDate) {
      countPrevious++;
    }
  });

  if (countPrevious === 0) {
    return countCurrent > 0 ? 100 : 0;
  }

  const variation = ((countCurrent - countPrevious) / countPrevious) * 100;
  return variation.toFixed(2);
};

export const getFidelity = (events) => {
  if (events.length === 0) return 0;
  const playerParticipation = {};

  events.forEach((event) => {
    event.players.forEach((player) => {
      if (!playerParticipation[player]) {
        playerParticipation[player] = 0;
      }
      playerParticipation[player]++;
    });
  });

  const loyalPlayers = Object.values(playerParticipation).filter(
    (count) => count >= 2
  ).length;
  const totalPlayers = Object.keys(playerParticipation).length;
  return (loyalPlayers / totalPlayers).toFixed(2) * 100;
};

export const getFidelityVariation = (events) => {
  const today = new Date();
  const startDate = subDays(today, 30);
  const startDatePrev = subDays(today, 60);

  const eventsCurrent = events.filter((event) => {
    const eventDate = new Date(
      event.date._seconds * 1000 + event.date._nanoseconds / 1000000
    );
    return eventDate >= startDate && eventDate <= today;
  });
  const eventsPrevious = events.filter((event) => {
    const eventDate = new Date(
      event.date._seconds * 1000 + event.date._nanoseconds / 1000000
    );
    return eventDate >= startDatePrev && eventDate < startDate;
  });
  const fidelityCurrent = getFidelity(eventsCurrent);
  const fidelityPrevious = getFidelity(eventsPrevious);
  if(fidelityPrevious === 0) return "+100.0%";
  const variation =
    ((fidelityCurrent - fidelityPrevious) / fidelityPrevious) * 100;
  if (isNaN(variation)) return "0.0%";
  return variation >= 0
    ? `+${variation.toFixed(1)}%`
    : `${variation.toFixed(1)}%`;
};

export const getNbBookingsThisMonth = (events) => {
  const currentMonth = new Date().getMonth();
  const currentYear = new Date().getFullYear();

  const countThisMonth = events.filter((event) => {
    const eventDate = new Date(
      event.date._seconds * 1000 + event.date._nanoseconds / 1000000
    );
    return (
      eventDate.getMonth() === currentMonth &&
      eventDate.getFullYear() === currentYear
    );
  }).length;

  return countThisMonth;
};

export const getNbBookingsThisMonthVariation = (events) => {
  const currentMonth = new Date().getMonth();
  const currentYear = new Date().getFullYear();
  const previousMonth = currentMonth === 0 ? 11 : currentMonth - 1;
  const previousYear = currentMonth === 0 ? currentYear - 1 : currentYear;

  const countThisMonth = getNbBookingsThisMonth(events);

  const countPreviousMonth = events.filter((event) => {
    const eventDate = new Date(
      event.date._seconds * 1000 + event.date._nanoseconds / 1000000
    );
    return (
      eventDate.getMonth() === previousMonth &&
      eventDate.getFullYear() === previousYear
    );
  }).length;

  if (countPreviousMonth === 0) {
    return countThisMonth > 0 ? "+100.0%" : "0.0%";
  }

  const variation =
    ((countThisMonth - countPreviousMonth) / countPreviousMonth) * 100;
  return variation >= 0
    ? `+${variation.toFixed(1)}%`
    : `${variation.toFixed(1)}%`;
};

export const getNbDifferentPlayers = (events) => {
  const playersSet = new Set();

  events.forEach((event) => {
    event.players.forEach((player) => {
      playersSet.add(player);
    });
  });
  return playersSet.size;
};

export const getNbDifferentPlayersVariation = (events) => {
  const today = new Date();
  const startDate = subDays(today, 30);
  const startDatePrev = subDays(today, 60);

  const playersCurrent = new Set();
  const playersPrevious = new Set();

  events.forEach((event) => {
    const eventDate = new Date(
      event.date._seconds * 1000 + event.date._nanoseconds / 1000000
    );
    event.players.forEach((player) => {
      if (eventDate >= startDate && eventDate <= today) {
        playersCurrent.add(player);
      } else if (eventDate >= startDatePrev && eventDate < startDate) {
        playersPrevious.add(player);
      }
    });
  });

  const countCurrent = playersCurrent.size;
  const countPrevious = playersPrevious.size;

  const variation = ((countCurrent - countPrevious) / countPrevious) * 100;
  
  if(variation === Infinity) return "+100.0%";

  return variation >= 0
    ? `+${variation.toFixed(1)}%`
    : `${variation.toFixed(1)}%`;
};

//getNumber of events validated
export const getNbEventsValidated = (events) => {
  const count = events.filter((event) => event.state === "validated").length;
  return count;
};

export const getNbEventsValidatedVariation = (events) => {
  const today = new Date();
  const startDate = subDays(today, 30);
  const startDatePrev = subDays(today, 60);

  const countCurrent = events.filter(
    (event) =>
      event.state === "validated" &&
      new Date(
        event.date._seconds * 1000 + event.date._nanoseconds / 1000000
      ) >= startDate &&
      new Date(
        event.date._seconds * 1000 + event.date._nanoseconds / 1000000
      ) <= today
  ).length;

  const countPrevious = events.filter(
    (event) =>
      event.state === "validated" &&
      new Date(
        event.date._seconds * 1000 + event.date._nanoseconds / 1000000
      ) >= startDatePrev &&
      new Date(
        event.date._seconds * 1000 + event.date._nanoseconds / 1000000
      ) < startDate
  ).length;

  if (countPrevious === 0) {
    return countCurrent > 0 ? "+100.0%" : "0.0%";
  }

  const variation = ((countCurrent - countPrevious) / countPrevious) * 100;
  console.log("variation", variation)

  return variation >= 0
    ? `+${variation.toFixed(1)}%`
    : `${variation.toFixed(1)}%`;
}

export const formatEventsForBookingChart = (events, range = "30days") => {
  let dateInterval;
  if (range === "12months") {
    const today = new Date();
    const startDate = subMonths(today, 11);
    dateInterval = eachMonthOfInterval({
      start: startOfMonth(startDate),
      end: endOfMonth(today),
    });
  } else {
    const days = range === "7days" ? 7 : 30;
    const today = new Date();
    const startDate = subDays(today, days);
    dateInterval = eachDayOfInterval({ start: startDate, end: today });
  }

  const eventCounts = {};
  events.forEach((event) => {
    const eventDate = new Date(
      event.date._seconds * 1000 + event.date._nanoseconds / 1000000
    );
    const formattedDate =
      range === "12months"
        ? format(eventDate, "yyyy-MM")
        : format(eventDate, "yyyy-MM-dd");

    if (!eventCounts[formattedDate]) {
      eventCounts[formattedDate] = 0;
    }
    eventCounts[formattedDate]++;
  });

  const data = dateInterval.map((date) => {
    const formattedDate =
      range === "12months"
        ? format(date, "yyyy-MM")
        : format(date, "yyyy-MM-dd");
    return {
      name: formattedDate,
      reservations: eventCounts[formattedDate] || 0,
    };
  });
  return data;
};

export const formatEventsForNewPlayersChart = (events, range = "30days") => {
  let dateInterval;

  // Définir l'intervalle de dates en fonction de la portée
  if (range === "12months") {
    const today = new Date();
    const startDate = subMonths(today, 11);
    dateInterval = eachMonthOfInterval({
      start: startOfMonth(startDate),
      end: endOfMonth(today),
    });
  } else {
    const days = range === "7days" ? 7 : 30;
    const today = new Date();
    const startDate = subDays(today, days);
    dateInterval = eachDayOfInterval({ start: startDate, end: today });
  }

  const newPlayersCounts = {};
  const playersSeen = new Set();

  // Parcourir les événements pour calculer les nouveaux joueurs par date
  events.forEach((event) => {
    const eventDate = new Date(
      event.date._seconds * 1000 + event.date._nanoseconds / 1000000
    );
    const formattedDate =
      range === "12months"
        ? format(eventDate, "yyyy-MM")
        : format(eventDate, "yyyy-MM-dd");

    if (!newPlayersCounts[formattedDate]) {
      newPlayersCounts[formattedDate] = 0;
    }

    event.players.forEach((player) => {
      if (!playersSeen.has(player)) {
        playersSeen.add(player);
        newPlayersCounts[formattedDate]++;
      }
    });
  });

  // Construire les données pour le graphique
  const data = dateInterval.map((date) => {
    const formattedDate =
      range === "12months"
        ? format(date, "yyyy-MM")
        : format(date, "yyyy-MM-dd");
    return {
      name: formattedDate,
      nouveaux_joueurs: newPlayersCounts[formattedDate] || 0,
    };
  });
  return data;
};

export const formatEventsForNbPlayersChart = (events, range = "30days") => {
  let dateInterval;

  // Définir l'intervalle de dates en fonction de la portée
  if (range === "12months") {
    const today = new Date();
    const startDate = subMonths(today, 11);
    dateInterval = eachMonthOfInterval({
      start: startOfMonth(startDate),
      end: endOfMonth(today),
    });
  } else {
    const days = range === "7days" ? 7 : 30;
    const today = new Date();
    const startDate = subDays(today, days);
    dateInterval = eachDayOfInterval({ start: startDate, end: today });
  }

  const playersCounts = {};

  // Parcourir les événements pour calculer le nombre total de joueurs par date
  events.forEach((event) => {
    const eventDate = new Date(
      event.date._seconds * 1000 + event.date._nanoseconds / 1000000
    );
    const formattedDate =
      range === "12months"
        ? format(eventDate, "yyyy-MM")
        : format(eventDate, "yyyy-MM-dd");

    if (!playersCounts[formattedDate]) {
      playersCounts[formattedDate] = 0;
    }

    playersCounts[formattedDate] += event.players.length;
  });

  // Construire les données pour le graphique
  const data = dateInterval.map((date) => {
    const formattedDate =
      range === "12months"
        ? format(date, "yyyy-MM")
        : format(date, "yyyy-MM-dd");
    return {
      name: formattedDate,
      joueurs: playersCounts[formattedDate] || 0,
    };
  });
  return data;
};

export const exportToCsv = async (bookings, popularGames, barUser) => {
  const date = format(new Date(), "dd-MM-yyyy");

  // Calculate the statistics
  const bookingsData = formatEventsForBookingChart(bookings, "12months");
  const newPlayersData = formatEventsForNewPlayersChart(bookings, "12months");
  const playersData = formatEventsForNbPlayersChart(bookings, "12months");
  const totalBookings = bookings.length;
  const totalBookingsVariation = calculateEventVariation(barUser.bookings);
  const fidelity = getFidelity(bookings);
  const fidelityVariation = getFidelityVariation(bookings);
  const nbBookingsThisMonth = getNbBookingsThisMonth(bookings);
  const nbBookingsThisMonthVariation = getNbBookingsThisMonthVariation(bookings);
  const nbDifferentPlayers = getNbDifferentPlayers(bookings);
  const nbDifferentPlayersVariation = getNbDifferentPlayersVariation(bookings);
  const nbEventsValidated = getNbEventsValidated(bookings);
  const nbEventsValidatedVariation = getNbEventsValidatedVariation(bookings);

  // Fetch the names of the popular games
  const popularGamesWithNames = await Promise.all(popularGames.map(async (game) => {
    const gameDetails = await getBoardGameWithId(game.gameId);
    return {
      name: gameDetails?.name || "Unknown Game",
      playCount: game.playCount
    };
  }));

  // Format the data for CSV
  const formattedData = [
    {
      section: "Monthly Data",
      data: bookingsData.map((item) => ({
        date: item.name,
        reservations: item.reservations,
        new_players: newPlayersData.find((n) => n.name === item.name)?.nouveaux_joueurs || 0,
        players: playersData.find((p) => p.name === item.name)?.joueurs || 0,
      })),
    },
    {
      section: "Summary",
      data: [
        { name: "Total Reservations", value: totalBookings },
        { name: "Bookings Variation", value: totalBookingsVariation },
        { name: "Fidelity", value: fidelity },
        { name: "Fidelity Variation", value: fidelityVariation },
        { name: "Bookings This Month", value: nbBookingsThisMonth },
        { name: "Bookings This Month Variation", value: nbBookingsThisMonthVariation },
        { name: "Different Players", value: nbDifferentPlayers },
        { name: "Different Players Variation", value: nbDifferentPlayersVariation },
        { name: "Events Validated", value: nbEventsValidated },
        { name: "Events Validated Variation", value: nbEventsValidatedVariation },
      ],
    },
    {
      section: "Popular Games",
      data: popularGamesWithNames.map((game) => ({
        name: game.name,
        playCount: game.playCount
      }))
    }
  ];

  // Convert the data to CSV
  const csvRows = [];

  formattedData.forEach((section) => {
    csvRows.push([section.section]); // Section title
    if (section.section === "Monthly Data") {
      csvRows.push(["Date", "Reservations", "New Players", "Players"]); // Headers for monthly data
      section.data.forEach((row) => {
        csvRows.push([
          row.date,
          row.reservations,
          row.new_players,
          row.players,
        ]);
      });
    } else if (section.section === "Popular Games") {
      csvRows.push(["Game Name", "Play Count"]); // Headers for popular games
      section.data.forEach((row) => {
        csvRows.push([
          row.name,
          row.playCount,
        ]);
      });
    } else {
      section.data.forEach((row, index) => {
        csvRows.push([row.name, row.value]);
        if (index % 2 === 1) { // Add a blank line after every second row (assuming each variation follows its related metric)
          csvRows.push([]);
        }
      });
    }
    csvRows.push([]); // Add a blank line for separation
  });

  // Create CSV blob and download it
  const csvContent = csvRows.map((e) => e.join(",")).join("\n");
  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  saveAs(blob, `analytics-${date}.csv`);
};

import React, { useState, useEffect } from "react";
import "./BarProfileScreen.css";
import Sidebar from "../../components/navBars/Sidebar";
import { Icon } from "semantic-ui-react";
import useBarAuthStore from "../../stores/bar_auth_store";
import { updateBarField } from "../../services/bars";
import BoardGameCard from "../../components/cards/BoarGameCard";
import BoardGameModal from "../../components/modals/BoardGameModal";
import { Segment, Checkbox } from "semantic-ui-react";
import { getBarSchedule } from "../../services/bars";
import BillingPortalButton from "../../components/subscriptions/BillingPortalButton";
import StripeCheckoutButton from "../../components/subscriptions/StripeCheckoutButton";

const BarProfileScreen = () => {
  const { barUser, updateBarUser } = useBarAuthStore();
  const [isModalOpen, setIsModalOpen] = useState(false); // État pour la modale

  const handleDelete = async (boardgameId) => {
    var ludotheque = barUser.ludotheque;
    ludotheque = ludotheque.filter((game) => game !== boardgameId);
    await updateBarField(barUser.id, "ludotheque", ludotheque);
    await updateBarUser();
  };

  return (
    <div>
      <Sidebar />
      <div className="profile-content">
        <div className="bar-header">
          <Icon name="info circle" size="large" color="blue" />
          <h4>Bienvenue sur votre tableau de bord.</h4>
        </div>

        <div className="profile-header card">
          <img src={barUser.bannerPicture} alt="banner" id="banner-picture" />
          <div className="profile-info">
            <img src={barUser.profilePicture} alt="profile" id="profile-picture" />
            <div style={{ textAlignLast: "start" }}>
              <h3>{barUser.name}</h3>
              <p>{barUser.address}</p>
              <p>{barUser.city}</p>
              <p>{barUser.country}</p>
            </div>
            <button
              className="edit-button"
              onClick={() => {
                // Redirection vers la page d'édition
                window.location.href = "edit-bar-profile";
              }}
            >
              Modifier le profil
            </button>
          </div>
        </div>

        <div className="subscription card">
          <p>
            {barUser.isSubscribed ? "Vous êtes abonné" : "Vous n'êtes pas abonné"}
          </p>
          {barUser.isSubscribed ? <BillingPortalButton /> : <StripeCheckoutButton />}
        </div>

        <Horaires barId={barUser.id} />

        <div className="ludotheque card">
          <h3>
            Ludothèque
            <span className="add-game" onClick={() => setIsModalOpen(true)}>
              +
            </span>
          </h3>
          <div className="games">
            {barUser.ludotheque.map((game) => (
              <BoardGameCard
                boardGameId={game}
                handleSelect={() => null}
                handleDelete={(boardgameId) => handleDelete(boardgameId)}
              />
            ))}
          </div>
        </div>
        <BoardGameModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          barId={barUser.id}
          updateBarUser={updateBarUser}
        />
      </div>
    </div>
  );
};

export default BarProfileScreen;

// Horaire
const Horaires = (props) => {
  const days = [
    "Lundi",
    "Mardi",
    "Mercredi",
    "Jeudi",
    "Vendredi",
    "Samedi",
    "Dimanche",
  ];

  const defaultDaySchedule = {
    enabled: false,
    openAm: "",
    closeAm: "",
    openPm: "",
    closePm: "",
    showDetails: false,
  };

  const [schedule, setSchedule] = useState(() =>
    days.reduce(
      (acc, day) => ({
        ...acc,
        [day]: defaultDaySchedule,
      }),
      {}
    )
  );

  useEffect(() => {
    const fetchSchedule = async () => {
      try {
        const fetchedSchedule = await getBarSchedule(props.barId);
        const completeSchedule = days.reduce((acc, day) => {
          acc[day] = fetchedSchedule[day] || defaultDaySchedule; // Assurez des valeurs par défaut
          return acc;
        }, {});
        setSchedule(completeSchedule);
      } catch (error) {
        console.error("Erreur lors du chargement des horaires :", error);
      }
    };

    fetchSchedule();
  }, [props.barId]);

  const removeShowDetails = (schedule) => {
    const cleanedSchedule = {};
    for (const day in schedule) {
      const { showDetails, ...rest } = schedule[day];
      cleanedSchedule[day] = rest;
    }
    return cleanedSchedule;
  };

  const handleToggle = (day) => {
    setSchedule((prevSchedule) => {
      const updatedSchedule = {
        ...prevSchedule,
        [day]: {
          ...prevSchedule[day],
          enabled: !prevSchedule[day]?.enabled || false, // Gérer les cas undefined
          showDetails: !prevSchedule[day]?.enabled || false,
        },
      };
      const scheduleForFirestore = removeShowDetails(updatedSchedule);
      updateBarField(props.barId, "schedule", scheduleForFirestore);
      return updatedSchedule;
    });
  };

  const handleTimeChange = (day, type, value) => {
    setSchedule((prevSchedule) => {
      const updatedSchedule = {
        ...prevSchedule,
        [day]: {
          ...prevSchedule[day],
          [type]: value || "", // Assurez-vous d'une valeur vide par défaut
        },
      };
      const scheduleForFirestore = removeShowDetails(updatedSchedule);
      updateBarField(props.barId, "schedule", scheduleForFirestore);
      return updatedSchedule;
    });
  };

  return (
    <div className="schedule card">
      <h3>Horaires</h3>
      {days.map((day) => (
        <Segment
          key={day}
          className={`day ${
            schedule[day]?.enabled ? "active" : ""
          }`}
        >
          <div className="day-header">
            <Checkbox
              toggle
              checked={!!schedule[day]?.enabled} // Vérification sécurisée
              onChange={() => handleToggle(day)}
              label={day}
            />
          </div>
          {schedule[day]?.enabled && schedule[day]?.showDetails && (
            <div className="time-inputs">
              <div className="time-pair">
                <label>Matin :</label>
                <select
                  value={schedule[day]?.openAm || ""}
                  onChange={(e) =>
                    handleTimeChange(day, "openAm", e.target.value)
                  }
                >
                  <option value="">Ouverture</option>
                  {[...Array(13).keys()].map((hour) => (
                    <option
                      key={hour}
                      value={`${hour}:00`}
                    >{`${hour}:00`}</option>
                  ))}
                </select>
                à
                <select
                  value={schedule[day]?.closeAm || ""}
                  onChange={(e) =>
                    handleTimeChange(day, "closeAm", e.target.value)
                  }
                >
                  <option value="">Fermeture</option>
                  {[...Array(13).keys()].map((hour) => (
                    <option
                      key={hour}
                      value={`${hour}:00`}
                    >{`${hour}:00`}</option>
                  ))}
                </select>
              </div>
              <div className="time-pair">
                <label>Soir :</label>
                <select
                  value={schedule[day]?.openPm || ""}
                  onChange={(e) =>
                    handleTimeChange(day, "openPm", e.target.value)
                  }
                >
                  <option value="">Ouverture</option>
                  {[...Array(13).keys()].map((hour) => (
                    <option
                      key={hour}
                      value={`${hour}:00`}
                    >{`${hour}:00`}</option>
                  ))}
                </select>
                à
                <select
                  value={schedule[day]?.closePm || ""}
                  onChange={(e) =>
                    handleTimeChange(day, "closePm", e.target.value)
                  }
                >
                  <option value="">Fermeture</option>
                  {[...Array(13).keys()].map((hour) => (
                    <option
                      key={hour}
                      value={`${hour}:00`}
                    >{`${hour}:00`}</option>
                  ))}
                </select>
              </div>
            </div>
          )}
        </Segment>
      ))}
    </div>
  );
};
import emailjs from "@emailjs/browser";

export const sendAfterSignupEmail = async (email, userId) => {
  try {
    await emailjs
      .send(
        "service_oz5dp3c",
        "template_qetedru",
        {
          name: "Ludomit",
          email: email,
          // antislash

          message:
            "Bonjour la team Ludomit, nous nous sommes inscrits sur votre plateforme et nous avons hâte de découvrir vos jeux ! Voici nos informations: \n" +
            "Email: " +email +
            "\n" +
            "userId: " + userId,
        },
        {
          publicKey: process.env.REACT_APP_EMAILJS_PUBLIC_KEY,
        }
      )
      .then(
        () => {
          console.log("SUCCESS!");
          return true;
        },
        (error) => {
          console.log("error in sending message", error.text);
          return false;
        }
      );
  } catch (error) {
    console.log("Erreur lors de l'envoi de l'email", error);
    return false;
  }
};

export const sendContactEmail = async (email, message) => {
  try {
    await emailjs
      .send(
        "service_oz5dp3c",
        "template_8j45fpj",
        {
          name: "Ludomit",
          email: email,
          message: message,
        },
        {
          publicKey: process.env.REACT_APP_EMAILJS_PUBLIC_KEY,
        }
      )
      .then(
        () => {
          console.log("SUCCESS!");
          return true;
        },
        (error) => {
          console.log("error in sending message", error.text);
          return false;
        }
      );
  } catch (error) {
    console.log("Erreur lors de l'envoi de l'email", error);
    return false;
  }
};
